<template>
  <div>
    <v-dialog
      v-model="Upoloadimage"
      persistent
      :max-width="cameraCheck == 0 && croppedImage == null ? '500px' : '800px'"
    >
      <v-card
        :height="cameraCheck == 0 && croppedImage == null ? '330px' : auto"
      >
        <v-toolbar dense class="elevation-0 gradient-bg white--text">
          Update Profile Pictutre
          <v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="6">
              <div
                class="font-weight-bold mt-2"
                v-if="!croppedImage"
                v-show="cameraCheck == 0"
              >
                Update your profile picture by uploading or taking a picture
              </div>
              <div class="font-weight-bold mt-2" v-if="croppedImage">
                Claer the image you have uploaded
              </div>
              <div class="font-weight-bold mt-2" v-show="cameraCheck == 1">
                When you see your picture on the screen, click on Capture Photo.
              </div>
            </v-col>
            <v-col cols="6">
              <v-btn
                class="gradient-bg white--text mt-2 ml-2 mb-2"
                @click="handleUpload()"
                v-if="!croppedImage"
                v-show="cameraCheck == 0"
                small
                ><v-icon class="mr-2" color="white">mdi-image</v-icon> Upload

                <input
                  ref="excel-upload-input"
                  class="excel-upload-input"
                  append-icon="mdi-paperclip"
                  type="file"
                  accept="image/*"
                  @change="handleImageUpload"
                />
              </v-btn>
              <v-btn
                class="gradient-bg white--text ml-2"
                small
                @click="opencameraupload()"
                v-show="cameraCheck == 0"
                v-if="!croppedImage"
              >
                Capture
              </v-btn>
              <v-btn
                :loading="delLoading"
                @click="delete_item()"
                v-if="croppedImage"
                small
                class="gradient-bg white--text ml-5"
                >Clear Image</v-btn
              >
              <v-btn
                :loading="delLoading"
                @click="stopCamera()"
                v-show="cameraCheck == 1"
                small
                class="gradient-bg white--text ml-5"
                >Stop Camera</v-btn
              >
            </v-col>
          </v-row>

          <div v-if="croppedImage">
            <v-row no-gutters>
              <v-row>
                <v-col cols="6">
                  <v-card flat width="300" height="auto" class="mt-6 ml-6 pb-2">
                    <vue-cropper
                      ref="cropper"
                      :src="croppedImage"
                      :guides="true"
                      :view-mode="1"
                      :drag-mode="null"
                      :background="false"
                      :auto-crop-area="1"
                      :aspect-ratio="1"
                      @cropend="handleImageCrop"
                    ></vue-cropper>
                  </v-card>
                  <div class="ml-8">
                    <b>Crop </b><v-icon class="primary--text">mdi-crop</v-icon>
                  </div>
                </v-col>
                <v-col cols="6">
                  <v-card flat width="300" height="auto" class="mt-6 ml-6 pb-2">
                    <v-img :src="altertedImage" contain height="220"></v-img>
                    <v-card-actions v-if="altertedImage" class="justify-end">
                      <v-btn
                        :loading="btnLoading"
                        @click="upload_S3()"
                        small
                        v-show="imageStatusCheck == false"
                        class="gradient-bg white--text mt-5"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-row>
          </div>
          <div v-else>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
              "
              class="mt-8 font-weight-bold"
              v-show="cameraCheck == 0"
            >
              No Image Upload
            </div>
          </div>
          <v-card-text align="center" v-if="cameraCheck == 1">
            <div v-if="cameras.length == 0">
              <div class="text-center font-weight-bold">
                No Camera Connected
              </div>
            </div>
            <div v-else>
              <v-select
                v-if="cameras.length > 1"
                v-model="selectedCameraIndex"
                :items="cameraOptions"
                style="max-width: 150px"
                outlined
                label="Select a Camera"
                class="mt-5"
                dense
              >
              </v-select>
              <div class="page-container">
                <div
                  class="video-container"
                  v-if="selectedCameraIndex !== null"
                >
                  <video
                    class="camera-video"
                    ref="videoElement"
                    autoplay
                  ></video>
                  <div class="face-overlay"></div>
                </div>
              </div>
              <v-card-actions class="justify-center">
                <v-btn
                  v-if="selectedCameraIndex !== null"
                  @click="capturePhoto"
                  :loading="buttonload"
                  class="gradient-bg white--text text-capitalize"
                >
                  Capture Photo
                </v-btn>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
var AWS = require("aws-sdk");
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { API, graphqlOperation } from "aws-amplify";
import { delete_face_from_collection } from "@/graphql/mutations.js";
export default {
  components: {
    VueCropper,
  },
  props: {
    Upoloadimage: Boolean,
    userDetailsObj: Object,
  },
  data: () => ({
    selectedCroppedImage: null,
    uploadedImage: null,
    croppedImage: null,
    altertedImage: null,
    imageStatusCheck: false,
    delLoading: false,
    stream: null,
    count: 0,
    cameras: [],
    latitude: null,
    longitude: null,
    error: false,
    buttonload: false,
    errorMessage: "",
    selectedCameraIndex: null,
    capturedPhoto: null,
    videoStream: null,
    isCameraAccessRequested: false,
    formattedDate: "",
    cameraCheck: 0,
    uploadedImageUrl: "",
  }),
  beforeDestroy() {
    this.stopCamera();
  },
  computed: {
    cameraOptions() {
      if (this.cameras.length === 2) {
        return [
          { text: "Device 1", value: 0 },
          { text: "Device 2", value: 1 },
        ];
      } else {
        return this.cameras.map((camera, index) => {
          return { text: camera.label, value: index };
        });
      }
    },
  },
  watch: {
    selectedCameraIndex(newIndex, oldIndex) {
      // console.log(newIndex);
      if (newIndex !== oldIndex) {
        this.stopCamera();
        if (newIndex !== null) {
          this.startCamera();
        }
      }
    },
  },
  methods: {
    async capturePhoto() {
      const canvas = document.createElement("canvas");
      canvas.width = this.$refs.videoElement.videoWidth;
      canvas.height = this.$refs.videoElement.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(this.$refs.videoElement, 0, 0, canvas.width, canvas.height);
      this.croppedImage = canvas.toDataURL("image/png");
      await this.stopCamera();
      this.cameraCheck = 0;
    },
    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: this.cameras[this.selectedCameraIndex].deviceId,
          },
        });
        this.$refs.videoElement.srcObject = stream;
      } catch (error) {
        // console.error("Error accessing webcam:", error);
      }
    },
    async opencameraupload() {
      this.cameraCheck = 1;
      this.isCameraAccessRequested = false;
      if (!this.isCameraAccessRequested) {
        await this.startCamera();
        this.isCameraAccessRequested = true;
      }

      await this.fectdetails();
      await this.loadCameras();
      if (this.cameras.length > 0) {
        this.selectedCameraIndex = 0; // Select the first camera by default
      }
    },
    fectdetails() {
      const currentDate = new Date();

      // Define the options for formatting
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are 0-based
      const year = currentDate.getFullYear();

      // Create the formatted date string
      this.formattedDate = `${day}-${month}-${year}`;

      // console.log(this.formattedDate,'5');
    },
    async loadCameras() {
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        try {
          const devices = await navigator.mediaDevices.enumerateDevices();
          this.cameras = devices.filter(
            (device) => device.kind === "videoinput"
          );
        } catch (error) {
          // console.error("Error enumerating devices:", error);
        }
      } else {
        // this.SnackBarComponent = {
        //   SnackbarVmodel: true,
        //   SnackbarColor: "red",
        //   Top: true,
        //   SnackbarText: "Devices is not supported.",
        // };
        // Handle the case where enumerateDevices is not available
        // console.error("enumerateDevices is not supported.");
        // You can provide a message to the user or take appropriate action here.
      }
    },
    stopCamera() {
      if (this.$refs.videoElement && this.$refs.videoElement.srcObject) {
        const tracks = this.$refs.videoElement.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
      this.cameraCheck = 0;
      // if (this.videoStream) {
      //   this.videoStream.getTracks().forEach((track) => track.stop());
      //   this.videoStream = null;
      // }
    },
    async upload_S3() {
      // console.log(this.croppedImage);
      // console.log(this.userDetailsObj);
      this.buttonload = true;
      if (this.userDetailsObj.face_s3_key) {
        await this.delete_face();
      } else {
        await this.upload_S3_image();
      }
    },
    async upload_S3_image() {
      var self = this;
      var data = this.altertedImage.replace(
        "data:" + "image/png" + ";base64,",
        ""
      );

      var buf = Buffer.from(data, "base64");
      var data = this.$store.getters.get_access_details;
      let uint8array = new Uint8Array(JSON.parse(data));
      // ENCODER

      var array = new TextDecoder("utf-8").decode(uint8array);
      var asseceskey = JSON.parse(array);
      var s3Bucket = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: asseceskey.access_key,
        secretAccessKey: asseceskey.secret_key,
      });
      var bucketName = "przncemembersprofile";

      // var bucketurl =
      //   "Monument-HeroImag" + "/" + Math.floor(new Date().getTime()) + ".png";
      //
      var params = {
        Bucket: bucketName,
        Key:
          this.$store.getters.get_org_details[0].organisation_id +
          "/" +
          this.userDetailsObj.user_id +
          "/" +
          ".png",
        Body: buf,
        ContentType: "media/png",
        ACL: "public-read-write",
      };
      var bucketurl =
        "https://" +
        bucketName +
        ".s3." +
        "ap-south-1" +
        ".amazonaws.com/" +
        params.Key;
      await s3Bucket.putObject(
        params,
        function (err, data) {
          if (err) {
            this.btnLoading = false;
            console.log(err);
          } else if (data) {
            this.imageStatusCheck = true;

            this.btnLoading = false;
            this.uploadedImageUrl = bucketurl;
            this.cameraCheck = 0;
            this.croppedImage = null;
            this.altertedImage = null;
            this.selectedCroppedImage = null;
            this.uploadedImage = null;
            this.imageStatusCheck = false;
            this.$emit("close_dailog_upload", 0);
          }
        }.bind(this)
      );
    },
    async delete_face() {
      this.adminLoading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(delete_face_from_collection, {
            input: {
              user_id: this.userDetailsObj.user_id,

              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );
        if (result.data.Message == "Face Id Removed Successfully!!!") {
          await this.upload_S3_image();
        }
        await this.upload_S3_image();
        this.buttonload = false;
        // var response = JSON.parse(result.data.delete_face_from_collection);
        // this.adminLoading = false;
        // if (response.Status == "SUCCESS") {
        //   this.$emit("successMsg", response.Message);
        //   this.adminLoading = false;
        // } else {
        //   this.$emit("errorMsg", response.Message);
        //   this.adminLoading = false;
        // }
      } catch (error) {
        console.log(error);
        // this.$emit("errorMsg", error.errors[0].message);
        this.adminLoading = false;
      }
    },
    delete_item() {
      this.croppedImage = null;
      this.altertedImage = null;
      this.selectedCroppedImage = null;
      this.uploadedImage = null;
      this.imageStatusCheck = false;
    },
    async close_dialog() {
      this.$emit("clicked", 0);
      this.cameraCheck = 0;
      this.croppedImage = null;
      this.altertedImage = null;
      this.selectedCroppedImage = null;
      this.uploadedImage = null;
      this.imageStatusCheck = false;
      await this.stopCamera();
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },

    handleImageCrop(data) {
      this.altertedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    handleImageUpload(event) {
      const file = event.target.files[0];

      this.selectedCroppedImage = event.target.files[0];
      this.uploadedImage = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.croppedImage = e.target.result;
      };

      reader.readAsDataURL(file);
    },
  },
};
</script>
  
<style scoped>
label {
  display: block;
  margin-top: -4px;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
</style>