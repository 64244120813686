<template>
  <div>
    <v-card class="elevation-0 overflow-hidden" flat>
      <v-toolbar
        dense
        elevation="0"
        class="ml-8"
        v-if="$vuetify.breakpoint.name != 'xs'"
      >
        <v-select
          v-model="shiftName"
          label="Select Shift"
          dense
          style="max-width: 220px"
          outlined
          :items="ShiftItems"
          item-text="workshift_name"
          item-value="workshift_id"
          @change="get_members_pagination()"
          class="mr-2 ml-n9 mt-11"
          md="5"
        ></v-select>

        <v-select
          label="Member Status"
          dense
          outlined
          item-text="text"
          item-value="value"
          :items="statusItems"
          style="max-width: 170px"
          v-model="memberStatus"
          @change="get_members_pagination()"
          class="mt-11 mr-2 v-select v-select-list"
        ></v-select>
        <v-text-field
          label="Search"
          style="max-width: 280px"
          v-model="search"
          class="mt-11 mr-2"
          dense
          append-icon="mdi-magnify"
          placeholder="Min 3 Character (Member Name)"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on }">
            <v-btn
              v-show="memberStatus == 'ACTIVE'"
              v-on="on"
              @click="addUserDialog = true"
              small
              class="gradient-bg white--text mr-2 mt-7"
            >
              <v-icon>mdi-plus</v-icon> Add Member
            </v-btn>
          </template>
          <span class="white--text">Add Members</span>
        </v-tooltip>

        <v-menu bottom right small :close-on-click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-on="on"
              v-bind="attrs"
              color="primary white--text"
              class="mt-7 mr-0 pl-5"
            >
              Actions
              <v-icon color="dark_primary " class="px-2"
                >mdi-chevron-down</v-icon
              >
            </v-btn>
          </template>
          <v-hover>
            <v-list class="py-0" dense width="130px" height="120">
              <!-- <v-list-item class="py-0 my-0"> -->

              <v-list-item class="py-1 my-0 px-1 mb-n2">
                <v-icon color="primary">mdi-download</v-icon>
                <v-list-item-title
                  @click="download_template()"
                  class="px-4 ml-n3 4 ml-n2"
                  ><a
                    ><span class="black--text">TEMPLATE</span></a
                  ></v-list-item-title
                >
              </v-list-item>

              <!-- <v-span>here px-0 will moves to left along with icon</v-span> -->

              <v-list-item class="py-0 my-0 px-1">
                <v-icon color="primary">mdi-upload</v-icon>
                <UploadExcel
                  :on-success="handleSuccess"
                  :before-upload="beforeUpload"
                />
                <v-list-item-title class="FontSize"></v-list-item-title>
              </v-list-item>

              <v-list-item class="px-1 mt-n1">
                <v-icon color="primary">mdi-file-excel</v-icon>
                <v-list-item-title
                  @click="generate_export()"
                  class="FontSize px-4 ml-n2"
                  ><a
                    ><span class="black--text">EXPORT</span>
                  </a></v-list-item-title
                >
                <!-- @click="export_excel()" -->
              </v-list-item>
              <!-- <v-list-item class="py-1 my-0 px-1 mt-n3">
                <v-icon color="primary">mdi-cloud-print-outline</v-icon>
                <v-list-item-title class="px-4 ml-n2"
                  ><a @click="view_memberprinttable(item)">
                    <span class="black--text">MAIL ID'S</span>
                  </a>
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-hover>
        </v-menu>
      </v-toolbar>
      <v-row no-gutters v-if="$vuetify.breakpoint.name == 'xs'">
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-toolbar dense elevation="0">
            <v-select
              label="Member Status"
              dense
              outlined
              item-text="text"
              item-value="value"
              :items="statusItems"
              style="width: 180px"
              v-model="memberStatus"
              @change="get_members_pagination()"
              class="mt-8 mr-2 v-select v-select-list"
            ></v-select>
            <v-text-field
              style="width: 600px"
              label="Search"
              v-model="search"
              class="mt-5 mr-2"
              dense
              placeholder="Min 3 Character"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </v-col>
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-toolbar dense elevation="0">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="addUserDialog = true"
                  small
                  class="gradient-bg white--text mr-3"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span class="white--text">Add Member</span>
            </v-tooltip>

            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="download_template()"
                  small
                  color="primary"
                  v-on="on"
                  class="gradient-bg white--text mr-2"
                >
                  <v-icon class="mr-2">mdi-download</v-icon>
                </v-btn>
              </template>
              <span
                v-text="$vuetify.breakpoint.name == 'xs' ? '' : 'Template'"
              ></span>
            </v-tooltip>

            <v-btn small class="gradient-bg white--text mr-2">
              <v-icon class="mr-2">mdi-upload</v-icon
              ><span
                v-text="$vuetify.breakpoint.name == 'xs' ? '' : 'Excel'"
              ></span>
            </v-btn>
            <v-btn
              @click="generate_export()"
              small
              class="gradient-bg white--text"
            >
              <!-- @click="export_excel()" -->
              <v-icon class="mr-2">mdi-file-excel</v-icon
              ><span
                v-text="$vuetify.breakpoint.name == 'xs' ? '' : 'Export'"
              ></span>
            </v-btn>
            <!-- <v-btn small class="gradient-bg white--text ml-2 mr-2">
              <v-icon @click="view_memberprinttable(item)" class="mr-2"
                >mdi-cloud-print-outline</v-icon
              ><span
                v-text="$vuetify.breakpoint.name == 'xs' ? '' : 'Member ID'"
              ></span>
            </v-btn> -->
          </v-toolbar>
        </v-col>
      </v-row>

      <v-card-text>
        <v-data-table
          :height="height"
          class="overflow-y-auto overflow"
          dense
          :headers="
            memberStatus == 'ACTIVE' ? MembersDataActive : MembersDataDeActive
          "
          :items="MembersItems"
          :items-per-page="15"
          :fixed-header="fixed"
          :options.sync="pagination"
          @update:options="get_members_by_status_new()"
          :loading="isLoadingMember"
          loading-text="Loading... Please wait"
          :search="search"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
          :sort-by="['user_name']"
          @click:row="view_member_swipes"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">{{
              memberStatus == "ACTIVE"
                ? `No members are active!`
                : "No members are Inactive!"
            }}</v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No results match your search criteria!
            </v-alert>
          </template>
          <template v-slot:[`item.face_s3_key`]="{ item }">
            <a @click.stop="view_profile(item)">
              <v-avatar size="36" class="ma-1" v-if="item.face_s3_key != null">
                <v-img
                  :src="`https://przncemembersprofile.s3.ap-south-1.amazonaws.com/${
                    item.face_s3_key
                  }?${new Date().getTime()}`"
                >
                </v-img>
              </v-avatar>
              <v-avatar size="45" v-else>
                <v-icon color="primary" large dark> mdi-account-circle </v-icon>
              </v-avatar>
            </a>
          </template>
          <template v-slot:[`item.user_full_contact_number`]="{ item }">
            <span
              v-if="
                item.user_contact_number != null ||
                item.user_contact_number != undefined
              "
              v-text="item.user_country_code + '-' + item.user_contact_number"
            ></span>
            <span v-else class="ml-5">-</span>
          </template>
          <template v-slot:[`item.is_reswipe_enabled`]="{ item }">
            <span class="ml-n8">
              <v-icon
                small
                :class="
                  item.is_reswipe_enabled == false ? 'red--text' : 'green--text'
                "
                >{{
                  item.is_reswipe_enabled == false ? "mdi-close" : "mdi-check"
                }}</v-icon
              ></span
            >
          </template>
          <template v-slot:[`item.app_device`]="{ item }">
            <span>
              <v-icon>{{
                item.device_type === "IOS"
                  ? "mdi-apple"
                  : item.device_type === "ANDROID"
                  ? "mdi-android"
                  : ""
              }}</v-icon>
              {{
                item.device_type == null || item.device_type == undefined
                  ? "-"
                  : ""
              }}
              {{
                item.app_version != null || item.app_version != undefined
                  ? item.app_version
                  : "-"
              }}
            </span>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip v-if="memberStatus == 'ACTIVE'" bottom color="primary">
              <template v-slot:activator="{ on }">
                <a @click.stop="view_member_qr(item)">
                  <v-icon v-on="on" small class="primary--text mr-1"
                    >mdi-qrcode
                  </v-icon>
                </a>
              </template>
              <span class="white--text">View Member QR</span>
            </v-tooltip>

            <v-tooltip v-if="memberStatus == 'ACTIVE'" bottom color="primary">
              <template v-slot:activator="{ on }">
                <a @click.stop="view_member_swipes(item)">
                  <v-icon v-on="on" small class="primary--text mr-1"
                    >mdi-eye
                  </v-icon>
                </a>
              </template>
              <span class="white--text">View Member Swipes</span>
            </v-tooltip>

            <v-tooltip v-if="memberStatus == 'ACTIVE'" bottom color="primary">
              <template v-slot:activator="{ on }">
                <a @click.stop="edit_member_details(item)">
                  <v-icon v-on="on" small class="primary--text mr-1"
                    >mdi-account-edit
                  </v-icon>
                </a>
              </template>
              <span class="white--text">Edit Member Details</span>
            </v-tooltip>
            <v-tooltip v-if="memberStatus == 'ACTIVE'" bottom color="primary">
              <template v-slot:activator="{ on }">
                <a @click.stop="view_track_swipes(item)">
                  <v-icon
                    v-on="on"
                    small
                    class="primary--text mr-1"
                  
                    >mdi-map-marker-account
                  </v-icon>
                </a>
              </template>
              <span class="white--text">View Live Location Tracking</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a @click.stop="delete_member_details(item)">
                  <v-icon
                    v-on="on"
                    small
                    :class="
                      memberStatus == 'ACTIVE' ? 'primary--text' : 'green--text'
                    "
                    >{{
                      memberStatus == "ACTIVE"
                        ? "mdi-delete"
                        : "mdi-account-reactivate"
                    }}
                  </v-icon>
                </a>
              </template>
              <span
                class="white--text"
                v-text="
                  memberStatus == 'ACTIVE'
                    ? 'De-Activate Member '
                    : 'Re-Activate Member '
                "
              ></span>
            </v-tooltip>
         
          </template>

          <template v-slot:[`item.user_email_id`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  v-text="
                    item.user_email_id != null ||
                    item.user_email_id != undefined
                      ? item.user_email_id.length >= 15
                        ? item.user_email_id.slice(0, 15) + '...'
                        : item.user_email_id
                      : '-'
                  "
                ></span>
              </template>
              <span class="white--text">{{ item.user_email_id }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.user_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  v-text="
                    item.user_name != null || item.user_name != undefined
                      ? item.user_name.length >= 15
                        ? item.user_name.slice(0, 15) + '...'
                        : item.user_name
                      : '-'
                  "
                ></span>
              </template>

              <span class="white--text">{{ item.user_name }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.user_deactivated_on`]="{ item }">
            <span
              v-text="
                item.user_deactivated_on != null ||
                item.user_deactivated_on != undefined
                  ? get_date(item.user_deactivated_on)
                  : '-'
              "
            ></span>
          </template>
          <template v-slot:[`item.relieving_date`]="{ item }">
            <span
              v-text="
                item.relieving_date != null || item.relieving_date != undefined
                  ? get_date(item.relieving_date).split(',')[0]
                  : '-'
              "
            ></span>
          </template>
          <template v-slot:[`item.user_created_on`]="{ item }">
            <span
              v-text="
                item.user_created_on != null ||
                item.user_created_on != undefined
                  ? get_date(item.user_created_on).split(',')[0]
                  : '-'
              "
            ></span>
          </template>
        </v-data-table>
      </v-card-text>

      <AddMember
        :addUserDialog="addUserDialog"
        @close_add_user="addUserDialog = false"
        @clicked="close_prop"
      />
      <EditMember
        :editUserDialog="editUserDialog"
        @close_edit_user="editUserDialog = false"
        :userDetailsObj="userDetailsObj"
        @clicked="close_prop"
      />
      <ViewMemberQR
        :viewMemberQRDialog="viewMemberQRDialog"
        @close_view_qr_dialog="viewMemberQRDialog = false"
        :userDetailsObj="userDetailsObj"
      />
      <ViewMemberProfile
        @close_view_profile_dialog="viewMembersProfile = false"
        :userDetailsObj="userDetailsObj"
        :viewMembersProfile="viewMembersProfile"
        @close_popup="call_api()"
      />
      <MemberPrintQr
        @close_viewMemberstable_dialog="viewMemberstable = false"
        :userDetailsObj="userDetailsObj"
        :viewMemberstable="viewMemberstable"
        @get_memberqr="get_memberqr"
      />
      <ViewMemberSwipes
        @close_view_member_details="viewMemberSwipesDialog = false"
        :viewMemberSwipesDialog="viewMemberSwipesDialog"
        :userDetailsObj="userDetailsObj"
        :toggle="toggle"
        :date="date"
      />
      <ViewMembertrack
        @close_view_member_details="viewMembertrackDialog = false"
        :viewMembertrackDialog="viewMembertrackDialog"
        :userDetailsObj="userDetailsObj"
        :toggle="toggle"
        :date="date"
      />

      <Overlay :overlay="overlay" />
      <DeleteMemberDialog
        :confDeleteMemberDialog="confDeleteMemberDialog"
        @close_conf_delete_dialog="confDeleteMemberDialog = false"
        :deleteText="deleteText"
        :deleteIcon="deleteIcon"
        :deleteColor="deleteColor"
        @success_conf_delete="deleteSuccessAction"
        :deleteLoading="deleteLoading"
        :date="date"
        :deleteUserItem="deleteUserItem"
        :memberStatus="memberStatus"
      />
      <SnackBar :SnackBarComponent="SnackBarComponent" />
      <MembersExcelUpload
        :EditUploadDialog="EditUploadDialog"
        @refresh="get_members_by_status_new"
        ref="editupload"
      />
    </v-card>
  </div>
</template>
<script>
/* eslint-disable */
import ViewMemberProfile from "@/components/Dialogs/ViewMemberProfile.vue";
import MemberPrintQr from "@/components/Dialogs/MemberPrintQr.vue";
import ViewMemberSwipes from "@/components/Dialogs/ViewMemberSwipes.vue";
import ViewMemberQR from "@/components/Dialogs/ViewMemberQR.vue";
import AddMember from "@/components/Dialogs/AddMember.vue";
import EditMember from "@/components/Dialogs/EditMember.vue";
import { API, graphqlOperation } from "aws-amplify";
import { list_all_users_v2 } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import DeleteMemberDialog from "@/components/Dialogs/ConfirmationDialogs/DeleteMemberDialog.vue";
import SnackBar from "@/components/SnackBar.vue";
import XLSX from "xlsx";
import { parseTime } from "@/utils";
import UploadExcel from "@/components/UploadExcel/index.vue";
import MembersExcelUpload from "@/views/ChildHomePages/MembersExcelUpload.vue";
import { Getshiftwork } from "@/mixins/GetWorkshift.js";
import { export_employee } from "@/graphql/mutations.js";
const FileSaver = require("file-saver");
import ViewMembertrack from "@/components/Dialogs/ViewMembertrack.vue";
import axios from "axios";
// import CountryList from "@/mixins/CountryList.json";
// import { ConsoleLogger } from "@aws-amplify/core";
export default {
  components: {
    AddMember,
    EditMember,
    ViewMembertrack,
    Overlay,
    ViewMemberQR,
    ViewMemberSwipes,
    DeleteMemberDialog,
    SnackBar,
    UploadExcel,
    MemberPrintQr,
    ViewMemberProfile,
    MembersExcelUpload,
    FileSaver,
  },
  mixins: [Getshiftwork],
  data: () => ({
    EditUploadDialog: false,
    SnackBarComponent: {},
    toggle: 0,
    memberStatus: "ACTIVE",
    statusItems: [
      { text: "ACTIVE", value: "ACTIVE" },
      { text: "INACTIVE", value: "DEACTIVE" },
    ],
    viewMemberQRDialog: false,
    date: new Date().toISOString().substr(0, 10),
    deleteLoading: false,
    confDeleteMemberDialog: false,
    deleteColor: "",
    deleteIcon: "",
    deleteText: "",
    viewMemberSwipesDialog: false,
    viewMembersProfile: false,
    viewMemberstable: false,
    viewMembertrackDialog: false,

    fixed: true,
    overlay: false,
    addUserDialog: false,
    editUserDialog: false,
    search: "",

    exceldata: [],

    isLoadingMember: false,

    userDetailsObj: {},
    MembersDataActive: [
      {
        text: "Member Profile",
        value: "face_s3_key",
        fixed: true,
        sortable: false,
        width: "50px",
      },

      { text: "Member Id", value: "employee_id", fixed: true, sortable: false },
      { text: "Member Name", value: "user_name", fixed: true, sortable: false },
      {
        text: "Contact Number",
        value: "user_full_contact_number",
        fixed: true,
        sortable: false,
      },
      {
        text: "Workshift",
        value: "workshift_name",
        fixed: true,
        sortable: false,
      },
      { text: "Enrolled On", value: "user_created_on", sortable: false },
      { text: "Device / App Version", value: "app_device", sortable: false },

      { text: "Actions", value: "Actions", sortable: false },
    ],
    MembersDataDeActive: [
      { text: "Member Id", value: "employee_id", fixed: true },
      { text: "Member Name", value: "user_name", fixed: true },
      {
        text: "Contact Number",
        value: "user_full_contact_number",
        fixed: true,
      },
      { text: "E-Mail", value: "user_email_id", fixed: true },
      { text: "Enrolled On", value: "user_created_on", sortable: false },
      { text: "User Deactivated", value: "user_deactivated_on", fixed: true },
      {
        text: "Relieving Date",
        value: "relieving_date",
        align: "center",
      },
      { text: "Device / App Version", value: "app_device" },
      { text: "Actions", value: "Actions" },
    ],
    MembersItems: [],
    TemplateItems: [
      {
        "Member Name *": "",
        "Member Id *": "",
        "E-Mail": "",
        "Country Code": "",
        "Contact Number": "",
        "Date Of Birth(DD-MM-YYYY)": "",
        Designation: "",
      },
    ],
    ShiftItems: [],
    shiftName: "ALL",
    pagination: {
      itemsPerPage: 10,
      page: 1,
    },
    deleteUserItem: {},

    // date: new Date().toISOString().substr(0, 10),
    next_token: null,
  }),
  watch: {
    // location(val) {
    //   console.log(val);
    // },
    search() {
      if (this.search != "") {
        if (this.search.length >= 2) {
          // console.log(this.search);
          this.get_members_by_status_new();
        }
      } else {
        this.get_members_by_status_new();
      }
    },
  },
  async created() {
    this.MembersItems = [];
    this.height = window.innerHeight - 195;
    await this.Getshiftwork();
    await this.fetchdetails();
    // await this.get_members_by_status_new();
  },
  methods: {
    call_api() {
      this.viewMembersProfile = false;
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Successfully updated profile picture",
      };
      this.get_members_by_status_new();
    },
    fetchdetails() {
      this.GetWorkshiftitems.forEach((element) => {
        this.ShiftItems.push({
          workshift_name: element.workshift_name,
          workshift_id: element.workshift_id,
        });
      });
      this.ShiftItems.unshift("ALL");
    },
    async generate_export() {
      this.loading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(export_employee, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );
        // console.log(result);
        var response = JSON.parse(result.data.export_employee);
        this.loading = false;
        if (response.Status == "SUCCESS") {
          // console.log(response.url);
          // console.log(response.url);
          await this.download_invoice(response.url);
          // await this.download_invoicea(response.url);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: response.Message,
          };
          // this.$emit("successMsg", response.Message);
          this.loading = false;
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: response.Message,
          };
          // this.$emit("errorMsg", response.Message);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        // this.$emit("errorMsg", error.errors[0].message);
        this.loading = false;
      }
    },
    download_invoice(item) {
      window.open(item);
      // console.log(item);
    },
    // async download_invoice(url) {
    //   var fileName = "Members.xlsx";
    //   try {
    //     // Make a GET request to the URL with responseType set to "blob"
    //     const response = await axios.get(url, { responseType: "blob" });

    //     // Create a Blob from the response data
    //     const blob = new Blob([response.data], {
    //       type: response.headers["content-type"],
    //     });

    //     // Use FileSaver to save the file
    //     FileSaver.saveAs(blob, fileName);

    //     console.log("File downloaded successfully");
    //   } catch (error) {
    //     console.error("Error downloading the file:", error);
    //   }
    // },
    async get_members_by_status_new() {
      this.isLoadingMember = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(list_all_users_v2, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              user_status: this.memberStatus,
              workshift_id:
                this.memberStatus == "ACTIVE" ? this.shiftName : "ALL",
              limit: 4000,
              nextToken: this.next_token,
              user_name_starts_with: this.search,
            },
          })
        );
        var response = result.data.list_all_users_v2.details;
        let array = this.MembersItems.concat(response);
        this.MembersItems = array;
        const uniqueArrayOfObjects = this.MembersItems.filter(
          (obj, index, self) =>
            index === self.findIndex((o) => o.user_id === obj.user_id)
        );
        this.MembersItems = uniqueArrayOfObjects;
        // console.log(this.MembersItems, " this.MembersItems");
        this.next_token = result.data.list_all_users_v2.nextToken;

        this.isLoadingMember = false;
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.isLoadingMember = false;
        this.overlay = false;
      }
    },
    get_members_pagination() {
      this.next_token = null;
      this.MembersItems = [];
      this.get_members_by_status_new();
    },
    close_prop(val) {
      if (val == 0) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter All the fields",
        };
      } else if (val == 1) {
        this.editUserDialog = false;
        this.addUserDialog = false;
        this.MembersItems = [];
        this.get_members_by_status_new();
      }
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    handleSuccess({ results, header }) {
      this.exceldata.tabledata = results;
      this.exceldata.tableheader = header;
      this.EditUploadDialog = true;
      this.$refs.editupload.getexceldata(this.exceldata);
    },
    export_excel() {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "Member Name",
          "Member Id",
          "Country Code",
          "Contact Number",
          "E-Mail",
          "Device Type",
          "App Version",
        ];
        let filterVal = [
          "user_name",
          "employee_id",
          "user_country_code",
          "user_contact_number",
          "Emails",
          "DeviceTypes",
          "Appversions",
        ];
        const list = this.MembersItems;
        list.forEach(function (findx) {
          findx.Emails =
            findx.user_email_id != null ? findx.user_email_id : "-";
          findx.DeviceTypes =
            findx.device_type != null ? findx.device_type : "-";
          findx.Appversions =
            findx.app_version != null ? findx.app_version : "-";
        });
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: `Prezence - Members List`,
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
    download_template: function () {
      const data = XLSX.utils.json_to_sheet(this.TemplateItems, {
        header: [
          "Member Name *",
          "Member Id *",
          "E-Mail",
          "Country Code",
          "Contact Number",
          "Date Of Birth(DD-MM-YYYY)",
          "Designation",
          "Employee Type",
        ],
      });

      // Set the date format for the "Date Of Birth(DD-MM-YYYY)" column
      XLSX.utils.format_cell(
        data,
        {
          D: "DD-MM-YYYY",
        },
        { header: "Date Of Birth(DD-MM-YYYY)" }
      );

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "upload template.xlsx");
    },
    deleteSuccessAction() {
      this.deleteLoading = false;
      this.confDeleteMemberDialog = false;
      this.MembersItems = [];
      this.get_members_by_status_new();
    },
    delete_member_details(item) {
      this.deleteUserItem = item;
      this.confDeleteMemberDialog = true;
      this.deleteText =
        this.memberStatus == "ACTIVE"
          ? `Are you sure you want to De-Activate the member ${item.user_name} ?`
          : `Are you sure you want to Re-Activate the member ${item.user_name}`;
      this.deleteIcon =
        this.memberStatus == "ACTIVE" ? "mdi-account-off" : "mdi-account-check";
      this.deleteColor = this.memberStatus == "ACTIVE" ? "red" : "green";
    },

    view_member_swipes(item) {
      this.userDetailsObj = item;
      this.viewMemberSwipesDialog = true;
    },
    view_track_swipes(item) {
      this.userDetailsObj = item;
      this.viewMembertrackDialog = true;
    },
    view_profile(item) {
      this.userDetailsObj = item;
      this.viewMembersProfile = true;
    },
    view_memberprinttable(item) {
      this.userDetailsObj = item;
      this.viewMemberstable = true;
    },
    view_member_qr(item) {
      this.userDetailsObj = item;

      this.viewMemberQRDialog = true;
    },
    edit_member_details(item) {
      this.userDetailsObj = item;
      this.editUserDialog = true;
    },
    get_memberqr() {
      this.viewMemberstable = false;
    },

    // async get_members_by_status(Status) {
    //   this.isLoadingMember = true;
    //   this.overlay = true;
    //   try {
    //     this.MembersItems = [];
    //     let result = await API.graphql(
    //       graphqlOperation(ListAllUsers, {
    //         input: {
    //           organisation_id:
    //             this.$store.getters.get_org_details[0].organisation_id,
    //         },
    //       })
    //     );

    //     if (Status == "ACTIVE") {
    //       this.MembersItems =
    //         result.data.ListAllUsers.ActiveMembers.length != 0
    //           ? result.data.ListAllUsers.ActiveMembers
    //           : [];

    //       if (this.shiftName !== "ALL")
    //         this.MembersItems = [...this.MembersItems].filter(
    //           (item) => item.workshift_id == this.shiftName
    //         );
    //     } else {
    //       this.MembersItems =
    //         result.data.ListAllUsers.InActivemembers.length != 0
    //           ? result.data.ListAllUsers.InActivemembers
    //           : [];
    //       if (this.shiftName !== "ALL")
    //         this.MembersItems = [...this.MembersItems].filter(
    //           (item) => item.workshift_id == this.shiftName
    //         );
    //     }
    //     // console.log(this.MembersItems, " this.MembersItems");
    //     this.isLoadingMember = false;
    //     this.overlay = false;
    //   } catch (err) {
    //     console.log("error", err);
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: err.errors[0].message,
    //     };
    //     this.isLoadingMember = false;
    //     this.overlay = false;
    //   }
    // },
  },
};
</script>

<style>
/* .v-main__wrap {
  overflow-y: hidden;
} */
</style>
