import {
    API,
    graphqlOperation
} from "aws-amplify";
import {
    listWorkshiftForOrganisation
} from "@/graphql/queries.js";

export const Getshiftwork = {
    data: () => ({
    
        GetWorkshiftitems: []
        
    }),
    methods: {
        async Getshiftwork() {
            try {
               
                let result = await API.graphql(
                    graphqlOperation(listWorkshiftForOrganisation, {
                        input: {
                            organisation_id:
                              this.$store.getters.get_org_details[0].organisation_id,
                              workshift_status: "ACTIVE",
                          },
                    })
                );
                this.GetWorkshiftitems = [];
                var response=JSON.parse( result.data.listWorkshiftForOrganisation).Items;
                this.GetWorkshiftitems=response;
            } catch (err) {
                this.GetWorkshiftitems = [];
                console.log("erro location", err);
                this.$store.commit("snackbar/showMessage", {
                    content: err.errors[0].message,
                    color: "red",
                  });
            }
        },
    }
}