<template>
  <v-dialog v-model="reasonDialog" persistent width="500px">
    <v-toolbar dense dark class="gradient-bg2" elevation="0">
      <v-toolbar-title>Invalid Reason(s) </v-toolbar-title>
      <v-spacer />
      <v-btn text dark @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card class="overflow-hidden">
      <v-row class="ma-2" v-for="(reas, i) in ReasonsObject.reasons" :key="i">
        <ul>
          <li>
            {{ reas }}
          </li>
        </ul>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    reasonDialog: Boolean,
    ReasonsObject: Object,
  },
  data: () => ({}),
  watch: {
    reasonDialog(val) {
      if (val == true) {
        // console.log("ReasonsObject", this.ReasonsObject);
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closedialog");
    },
  },
};
</script>

<style>
</style>