<template>
  <div>
    <v-dialog v-model="viewMemberQRDialog" persistent width="420px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title
          >QR for
          {{
            userDetailsObj.user_name != undefined
              ? userDetailsObj.user_name
              : userDetailsObj.qr_location_name
          }}</v-toolbar-title
        >
        <v-spacer />

        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow--hidden">
        <v-card-text>
          <v-card-text align="center" justify="center">
            <v-img
              class="ml-1"
              contain
              swidth="100px"
              v-if="url"
              :src="url"
              height="100px"
            >
            </v-img>
            <v-img
              v-else
              src="@/assets/prezsqu.png"
              class="mt-n5"
              height="145"
              width="145"
            >
            </v-img>
          </v-card-text>
          <v-row
            align="center"
            justify="center"
            v-if="userDetailsObj.face_s3_key != null"
          >
            <v-col
              cols="6"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              class="d-flex justify-center"
            >
              <div
                style="
                  border: 1px solid gray;
                  width: fit-content;
                  margin-left: 10px;
                "
              >
                <v-img
                  height="135"
                  width="140"
                  :src="
                    'https://przncemembersprofile.s3.ap-south-1.amazonaws.com/' +
                    userDetailsObj.face_s3_key
                  "
                  contain
                ></v-img>
              </div>
            </v-col>
            <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="ml-n12">
              <v-card-text>
                <div
                  style="border: 1px solid gray; max-width: fit-content"
                  class="pa-3"
                >
                  <VueQRCodeComponent
                    :text="member_qr"
                    error-level="H"
                    :size="110"
                  ></VueQRCodeComponent>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-card-text class="mt-n5" align="center" justify="center">
              <div
                style="border: 1px solid gray; max-width: fit-content"
                class="pa-3"
              >
                <VueQRCodeComponent
                  :text="member_qr"
                  error-level="H"
                  :size="150"
                ></VueQRCodeComponent>
              </div>
            </v-card-text>
          </v-row>
          <v-card-text align="center" justify="center">
            <!-- <v-img src="@/assets/prezwww.png" class="mt-n3" max-width="180">
                </v-img
              > -->
            <span class="black--text subtitle-2">{{
              userDetailsObj.user_name
            }}</span
            ><br />
            <span class="black--text subtitle-2">{{
              userDetailsObj.employee_id
            }}</span
            ><br />
            <span class="black--text subtitle-2">Organisation Name</span><br />
            <span class="black--text subtitle-1">
              {{ $store.getters.get_org_details[0].organisation_name }}<br />
              {{ userDetailsObj.qr_location_name }} </span
            ><br />
            <span class="primary--text subtitle-2">www.prznce.com/App</span>
          </v-card-text>
        </v-card-text>
        <v-card-text align="center" justify="center">
          <v-btn
            class="gradient-bg white--text mt-n5 ml-3"
            @click="generateReport()"
            ><v-icon medium class="mr-2">mdi-qrcode-scan</v-icon>Print QR</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="viewMemberQRDialog == true">
      <v-card>
        <v-card-text align="center" justify="center" style="margin-left: -10px">
          <vue-html2pdf
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :filename="
              userDetailsObj.user_name != undefined
                ? userDetailsObj.user_name
                : userDetailsObj.qr_location_name + ' - QR'
            "
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a6"
            pdf-orientation="portrait"
            :paginate-elements-by-height="800"
            pdf-content-width="420px"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <v-card-text align="center" justify="center">
                <img
                  class="ml-2"
                  contain
                  swidth="100px"
                  v-if="url"
                  :src="url"
                  height="100px"
                />

                <img
                  v-else
                  src="@/assets/prezsqu.png"
                  class="mt-n5"
                  height="130"
                  width="130"
                />
              </v-card-text>
              <v-row
                align="center"
                justify="center"
                v-if="userDetailsObj.face_s3_key != null"
              >
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  class="d-flex justify-center mr-n3"
                >
                  <div
                    style="
                      border: 1px solid black;
                      width: fit-content;
                      margin-left: 70px;
                      margin-top: 16px;
                    "
                  >
                    <img
                      width="120px"
                      height="110px"
                      style="
                        padding-top: 3px;
                        padding-right: 3px;
                        padding-left: 3px;
                      "
                      
                      :src="
                        'https://przncemembersprofile.s3.ap-south-1.amazonaws.com/' +
                        userDetailsObj.face_s3_key
                      "
                    />
                  </div>
                </v-col>
                <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <v-card-text>
                    <div
                      style="
                        border: 1px solid black;
                        max-width: fit-content;
                        margin-left: -30px;
                      "
                    >
                      <VueQRCodeComponent
                        :text="member_qr"
                        error-level="H"
                        :size="110"
                        style="padding: 2px"
                      ></VueQRCodeComponent>
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-card-text
                  class="mt-n5 mr-n3"
                  align="center"
                  justify="center"
                >
                  <div
                    style="border: 1px solid black; max-width: fit-content"
                    class="pa-3"
                  >
                    <VueQRCodeComponent
                      :text="member_qr"
                      error-level="H"
                      :size="150"
                    ></VueQRCodeComponent>
                  </div>
                </v-card-text>
              </v-row>
              <v-card-text align="center" justify="center">
                <!-- <v-img src="@/assets/prezwww.png" class="mt-n3" max-width="180">
                </v-img
              > -->
                <span class="black--text subtitle-2">{{
                  userDetailsObj.user_name
                }}</span
                ><br />
                <span class="black--text subtitle-2">{{
                  userDetailsObj.employee_id
                }}</span
                ><br />
                <span class="black--text subtitle-2">Organisation Name</span
                ><br />
                <span class="black--text subtitle-1">
                  {{ $store.getters.get_org_details[0].organisation_name
                  }}<br />
                  {{ userDetailsObj.qr_location_name }} </span
                ><br />
                <span class="primary--text subtitle-2">www.prznce.com/App</span>
              </v-card-text>
            </section>
          </vue-html2pdf>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import VueQRCodeComponent from "vue-qr-generator";
import VueHtml2pdf from "vue-html2pdf";
export default {
  props: {
    viewMemberQRDialog: Boolean,
    userDetailsObj: Object,
  },
  components: { VueQRCodeComponent, VueHtml2pdf },
  data: () => ({
    member_qr: "",
    url: "",
  }),
  created() {
    this.url = this.$store.getters.get_org_details[0].organisation_logo;
  },
  watch: {
    viewMemberQRDialog(val) {
      // console.log(val);

      if (val == true) {
        this.member_qr = true;

        // console.log("asd", this.userDetailsObj);
        this.member_qr =
          this.userDetailsObj.user_id != undefined
            ? `https://www.prznce.com/Apps/myprezence?qr_id=PROXY#${this.userDetailsObj.user_id}`
            : `https://www.prznce.com/Apps/myprezence?qr_id=${this.userDetailsObj.qr_id}`;
        // console.log(this.member_qr, "member_qr");
      }
    },
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    close_dialog() {
      this.$emit("close_view_qr_dialog");
    },
  },
};
</script>

<style></style>
