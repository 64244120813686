<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-toolbar dense dark class="gradient-bg2">
      <v-toolbar-title class="white--text mt-n3"
        >Upload Member Details</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn class="mt-n3" text dark @click="cleardata">
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card class="elevation-0">
      <v-row class="mx-3">
        <v-col cols="12" md="3">
          <v-row class="mt-2">
            <v-select
              shaped
              outlined
              label="Member Name"
              class=""
              v-model="EmpName"
              :items="headerList"
              dense
            >
            </v-select> </v-row
          ><v-row>
            <v-select
              shaped
              outlined
              label="Member Id"
              v-model="EmpId"
              :items="headerList"
              dense
            >
            </v-select> </v-row
          ><v-row>
            <v-select
              shaped
              outlined
              label="E-Mail"
              v-model="Email"
              :items="headerList"
              dense
            >
            </v-select>
          </v-row>
          <v-row>
            <v-select
              shaped
              outlined
              label="Country Code"
              v-model="CountryCode"
              :items="headerList"
              dense
            >
            </v-select>
          </v-row>
          <v-row>
            <v-select
              shaped
              outlined
              label="Contact Number"
              v-model="ContNum"
              :items="headerList"
              dense
            >
            </v-select>
          </v-row>
          <v-row>
            <v-select
              shaped
              outlined
              label="Date Of Birth"
              v-model="date_of_birth"
              :items="headerList"
              dense
            >
            </v-select>
          </v-row>
          <v-row>
            <v-select
              shaped
              outlined
              label="Date Of Joining"
              v-model="date_of_join"
              :items="headerList"
              dense
            >
            </v-select>
          </v-row>
          <v-row>
            <v-select
              shaped
              outlined
              label="Desgination"
              v-model="Empdesgination"
              :items="headerList"
              dense
            >
            </v-select>
          </v-row>
          <v-row>
            <v-select
              shaped
              outlined
              label="Employee Type"
              v-model="Employeetype"
              :items="headerList"
              dense
            >
            </v-select>
          </v-row>
          <v-row justify="center">
            <v-spacer />
            <v-btn
              v-if="EmpName != '' && EmpId != ''"
              @click="validate_number()"
              class="gradient-bg white--text"
              shaped
              dark
              tile
              >Preview</v-btn
            >
          </v-row>
        </v-col>
        <v-col cols="12" md="9">
          <v-card
            v-if="headerList.length != 0 && StoreTableData.length != 0"
            flat
          >
            <v-tabs v-model="model" centered slider-color="primary">
              <v-tab>Valid</v-tab>
              <v-tab>
                Invalid
                <v-badge color="red" :content="UploadList.invalid.length">
                </v-badge
              ></v-tab>
            </v-tabs>
            <v-tabs-items v-model="model">
              <v-tab-item>
                <v-card flat v-if="model == 0">
                  <v-data-table
                    :headers="ValidHeader"
                    :items="UploadList.valid"
                    dense
                  >
                    <template v-slot:[`no-data`]>
                      <v-alert class="gradient-bg2 white--text"
                        >No details are Valid. Click on Invalid to
                        proceed!</v-alert
                      >
                    </template>
                  </v-data-table>
                  <v-card-actions></v-card-actions>
                  <v-card-actions>
                    <v-row justify="center">
                      <v-btn
                        @click="updateMember"
                        v-if="UploadList.valid.length != 0"
                        :loading="isLoadingUpload"
                        class="gradient-bg white--text"
                        ><v-icon class="mr-2">mdi-plus</v-icon> Upload
                        Members</v-btn
                      >
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat v-if="model == 1">
                  <v-data-table
                    :headers="InValidHeader"
                    :items="UploadList.invalid"
                    dense
                  >
                    <template v-slot:[`no-data`]>
                      <v-alert class="gradient-bg2 white--text"
                        >No details are Invalid. Click on valid to
                        proceed!</v-alert
                      >
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                          <a @click="openreasondialog(item)">
                            <v-icon
                              small
                              v-on="on"
                              class="mr-2"
                              color="primary"
                            >
                              mdi-information
                            </v-icon>
                          </a>
                        </template>
                        <span class="white--text">View Reason</span>
                      </v-tooltip>
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                          <a @click="editItem(item)">
                            <v-icon
                              small
                              v-on="on"
                              color="primary"
                              class="mr-2"
                            >
                              mdi-pencil
                            </v-icon>
                          </a>
                        </template>
                        <span class="white--text">Edit Member Details</span>
                      </v-tooltip>
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                          <a @click="deleteitem(item)">
                            <v-icon
                              small
                              v-on="on"
                              color="primary"
                              class="mr-5"
                            >
                              mdi-delete
                            </v-icon>
                          </a>
                        </template>
                        <span class="white--text">Delete current record</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:top>
                      <v-spacer></v-spacer>
                      <v-dialog
                        v-model="dialogEdited"
                        persistent
                        max-width="600px"
                      >
                        <v-card flat>
                          <v-toolbar dense dark class="gradient-bg2">
                            <v-toolbar-items
                              ><v-card-title>
                                <span class="white--text titcss"
                                  >Edit Member</span
                                >
                              </v-card-title>
                            </v-toolbar-items>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="white"
                              dark
                              @click="dialogEdited = false"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-form
                            ref="form"
                            v-model="valid"
                            :lazy-validation="lazy"
                          >
                            <v-card-text>
                              <v-row class="mx-3">
                                <v-text-field
                                  v-model="editStoreObj[EmpId]"
                                  :rules="idrules"
                                  color="primary"
                                  label="Member ID"
                                ></v-text-field>
                              </v-row>
                              <v-row class="mx-3">
                                <v-text-field
                                  v-model="editStoreObj[EmpName]"
                                  :rules="namerules"
                                  color="primary"
                                  label="Member Name"
                                ></v-text-field>
                              </v-row>
                              <v-row class="mx-3">
                                <v-text-field
                                  :disabled="Empdesgination == ''"
                                  v-model="editStoreObj[Empdesgination]"
                                  :rules="validationRules"
                                  color="primary"
                                  label="Desgination Name"
                                ></v-text-field>
                              </v-row>
                              <v-row class="mx-3">
                                <v-text-field
                                  :disabled="Employeetype == ''"
                                  v-model="editStoreObj[Employeetype]"
                                  :rules="validationemptypeRules"
                                  color="primary"
                                  label="Employeetype Type"
                                ></v-text-field>
                              </v-row>
                              <v-row class="mx-3">
                                <v-text-field
                                  :disabled="Email == ''"
                                  v-model="editStoreObj[Email]"
                                  :rules="emailRules"
                                  color="primary"
                                  label="Email ID"
                                ></v-text-field>
                              </v-row>
                              <v-row class="mx-3">
                                <v-text-field
                                  :disabled="date_of_birth == ''"
                                  v-model="editStoreObj[date_of_birth]"
                                  :rules="dateOfBirthRule"
                                  color="primary"
                                  label="Date Of Birth"
                                ></v-text-field>
                              </v-row>
                              <v-row class="mx-3">
                                <v-text-field
                                  :disabled="date_of_join == ''"
                                  v-model="editStoreObj[date_of_join]"
                                  :rules="dateOfBirthRule"
                                  color="primary"
                                  label="Date Of Join"
                                ></v-text-field>
                              </v-row>
                              <v-row class="mx-0 mt-n3">
                                <v-col cols="12" md="3">
                                  <v-select
                                    label="CountryCode"
                                    :disabled="CountryCode == ''"
                                    :rules="getRules()"
                                    v-model="editStoreObj[CountryCode]"
                                    :items="CountryList"
                                    item-value="phonecode"
                                    item-text="name"
                                  >
                                    <template
                                      slot="selection"
                                      slot-scope="{ item }"
                                    >
                                      {{ item.flag }} ({{
                                        item.phonecode
                                          .toString()
                                          .includes("+") == false
                                          ? "+" + item.phonecode
                                          : item.phonecode
                                      }})
                                    </template>
                                    <template slot="item" slot-scope="{ item }">
                                      {{ item.flag }} {{ item.name }} ({{
                                        item.phonecode
                                      }})
                                    </template>
                                  </v-select>
                                </v-col>
                                <v-col cols="12" md="9">
                                  <v-text-field
                                    :rules="ContNum !== '' ? numberRules : []"
                                    :disabled="ContNum == ''"
                                    v-model="editStoreObj[ContNum]"
                                    color="primary"
                                    label="Contact Number"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                class="gradient-bg white--text mr-7 mb-8"
                                @click="ValidateAddDialog(editStoreObj)"
                                :loading="isLoadingEdit"
                                ><v-icon small class="mr-3"
                                  >mdi-content-save</v-icon
                                >
                                Save Details</v-btn
                              >
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
          <v-card v-else class="mt-10 elevation-10">
            <v-card-actions>
              <v-spacer />
              <v-card-actions class="mt-n10">
                <v-spacer />
              </v-card-actions>
            </v-card-actions>
            <v-card-text
              align="center"
              style="font-size: 20px"
              class="py-16 px-1"
              >Please Select The Respective Column Headers For
              <div class="ml-1 primary--text">
                Member Name, Member ID, Country Code & Contact Number
              </div>
              From The Excel Sheet For Preview.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <ReasonsDialog
      :ReasonsObject="ReasonsObject"
      :reasonDialog="reasonDialog"
      @closedialog="reasonDialog = false"
    />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </v-dialog>
</template>
<script>
import ReasonsDialog from "./ReasonsDialog.vue";
import { Country } from "country-state-city";
import { API, graphqlOperation } from "aws-amplify";
import { BulkUploadusers } from "@/graphql/mutations.js";
import SnackBar from "@/components/SnackBar.vue";

export default {
  components: {
    ReasonsDialog,
    SnackBar,
  },
  props: {
    EditUploadDialog: Boolean,
  },
  data: () => ({
    reasonDialog: false,
    SnackBarComponent: {},
    dialog: false,
    isLoadingUpload: false,
    valid: true,
    lazy: false,
    Snackbar: false,
    SnackbarText: "",
    color: "",
    dateOfBirthRule: [],
    date_of_birth: "",
    date_of_join: "",
    EmpName: "",
    EmpId: "",
    Email: "",
    CountryCode: "",
    ContNum: "",
    namerules: [
      (x) =>
        /^[a-z\sA-Z]{3,30}$/.test(x) ||
        "Name must be between 3 & 30 characters and Should not contain special characters",
      (x) => x.length != 0 || "Member Name cannot be empty",
    ],
    desginationrules: [
      (x) =>
        /^[a-z\sA-Z]{3,100}$/.test(x) ||
        "Designation must be between 3 & 100 characters and Should not contain special characters",
      (x) => x.length != 0 || "Designation Name cannot be empty",
    ],
    employeeTyperules: [
      (x) =>
        /^[a-z\sA-Z]{3,100}$/.test(x) ||
        "Employee Type must be between 3 & 100 characters and Should not contain special characters",
      (x) => x.length != 0 || "Employee Type cannot be empty",
    ],
    emailRules: [],
    idrules: [
      (v) => v.length != 0 || "Member Id cannot be empty",
      (v) =>
        /^[a-z\sA-Z0-9]{0,10}$/.test(v) ||
        "Member Id must be between 1 & 10 characters and Should not contain special characters",
    ],
    exceldata: { tabledata: null, tableheader: null },
    numberRules: [
      (v) => v.length != 0 || "Contact Number cannot be empty",
      (v) => /^[0-9]{8,12}$/.test(v) || "Contact Number must be valid",
    ],
    dialogEdited: false,
    item: {},
    isLoadingEdit: false,
    isDateValid: false,
    isDateJoinValid: false,
    UploadList: [],
    ReasonsObject: {},
    editedItem: {
      Name: "",
      Employee_ID: "",
      CountryCode: "",
      Contact_Number: "",
    },
    CountryList: [],
    StoreTableData: [],
    StoreValidUserArray: [],
    ExcelList: ["EmployeeName", "EmployeeId", "CountryCode", "ContactNum"],
    model: 0,
    ValidHeader: [],
    InValidHeader: [],
    ValidData: [],
    ValidList: [],
    headerList: [],
    swipelist: [],
    editStoreObj: {},
    dataList: [],
    InvalidData: [],
    InvalidList: [],
    Empdesgination: "",
    Employeetype: "",
    numberRegEx: "/^[0-9]+$/",
  }),
  computed: {
    validationRules() {
      // Only apply rules if Empdesgination is not empty
      return this.Empdesgination === "" ? [] : this.desginationrules;
    },
    validationemptypeRules() {
      return this.Employeetype === "" ? [] : this.employeeTyperules;
    },
  },
  methods: {
    getRules() {
      // Check if ContNum is not an empty string, then apply rules
      return this.ContNum.trim() !== ""
        ? [(v) => !!v || "Country Code is required"]
        : [];
    },
    openreasondialog(item) {
      this.ReasonsObject = item;
      this.reasonDialog = true;
    },
    dateOfBirthRule(value) {
      const datePattern =
        /^(0?[1-9]|[1-2][0-9]|3[0-1])-(0?[1-9]|1[0-2])-(\d{4})$/;
      if (!datePattern.test(value)) {
        return "Invalid date format. Use DD-MM-YYYY.";
      }

      const [, day, month, year] = value.match(datePattern);
      const dateObj = new Date(`${year}-${month}-${day}`);
      if (
        dateObj.getFullYear() !== Number(year) ||
        dateObj.getMonth() + 1 !== Number(month) ||
        dateObj.getDate() !== Number(day)
      ) {
        return "Invalid date. Please enter a valid date.";
      }

      // Optionally, you can add additional checks here, such as valid ranges for day, month, and year.

      return true; // Date is valid
    },
    cleardata() {
      (this.EmpName = ""),
        (this.EmpId = ""),
        (this.CountryCode = ""),
        (this.ContNum = ""),
        (this.Email = ""),
        (this.StoreTableData = []),
        (this.date_of_birth = ""),
        (this.date_of_join = "");
      (this.Empdesgination = ""), (this.Employeetype = "");
      this.dialog = false;
    },
    validateEmail(email) {
      if (email !== "") {
        this.emailRules = [
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ];
      } else {
        this.emailRules = [];
      }
    },
    validateDateOfBirth(date) {
      if (date !== "") {
        const dateParts = date.split(/-/); // Use only hyphen "-" as the separator
        if (dateParts.length === 3) {
          const day = parseInt(dateParts[0], 10);
          const month = parseInt(dateParts[1], 10);
          const year = parseInt(dateParts[2], 10);

          if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
            const dob = new Date(year, month - 1, day); // Month is zero-based
            if (
              dob.getDate() === day &&
              dob.getMonth() === month - 1 &&
              dob.getFullYear() === year
            ) {
              return true; // Date is valid
            }
          }
        }
        return false; // Date is invalid or not in the expected format
      } else {
        return false; // Date is empty or not in the expected format
      }
    },
    // ValidateAddDialog(item) {
    //   alert(this.date_of_birth)
    //   if (this.Email != "") {
    //     this.emailRules = [
    //       (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    //     ];
    //   } else if (this.Email == "") {
    //     this.emailRules = [];
    //   }
    //   if (this.date_of_birth != "") {
    //     alert(this.date_of_birth ,'ss')
    //     this.dateOfBirthRule = [
    //       (v) => {
    //         const datePattern =
    //           /^(0?[1-9]|[1-2][0-9]|3[0-1])-(0?[1-9]|1[0-2])-(\d{4})$/;
    //         if (!datePattern.test(v)) {
    //           return "Date of birth must be in the format DD-MM-YYYY.";
    //         }

    //         const [, day, month, year] = v.match(datePattern);
    //         const dateObj = new Date(`${year}-${month}-${day}`);
    //         if (
    //           dateObj.getFullYear() !== Number(year) ||
    //           dateObj.getMonth() + 1 !== Number(month) ||
    //           dateObj.getDate() !== Number(day)
    //         ) {
    //           return "Invalid date. Please enter a valid date of birth.";
    //         }

    //         // Optionally, you can add additional checks here, such as valid ranges for day, month, and year.

    //         return true; // Date is valid
    //       },
    //     ];
    //   } else if (this.date_of_birth == "") {
    //     this.dateOfBirthRule = [];
    //   }
    //   if (this.$refs.form.validate()) {
    //     this.UploadList.valid.push(item);
    //     this.UploadList.invalid.splice(item, 1);
    //     this.dialogEdited = false;
    //   } else {
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: "Enter Valid Inputs",
    //     };
    //   }
    // },
    ValidateAddDialog(item) {
      // console.log(item["Date Of Birth(DD-MM-YYYY)"]);
      this.validateEmail(this.Email);
      // console.log(this.date_of_birth);
      if (this.date_of_birth != "") {
        this.isDateValid = this.validateDateOfBirth(
          item["Date Of Birth(DD-MM-YYYY)"]
        );
      } else if (this.date_of_birth == "") {
        this.isDateValid = true;
      }
      if (this.date_of_join != "") {
        this.isDateJoinValid = this.validateDateOfBirth(
          item["Date Of Birth(DD-MM-YYYY)"]
        );
      } else if (this.date_of_join == "") {
        this.isDateJoinValid = true;
      }

      // this.validateDateOfBirth(this.date_of_birth);
      // console.log(isDateValid);

      if (
        this.$refs.form.validate() &&
        this.isDateValid &&
        this.isDateJoinValid
      ) {
        this.UploadList.valid.push(item);
        this.UploadList.invalid.splice(item, 1);
        this.dialogEdited = false;
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter Valid Inputs",
        };
      }
    },
    // Other methods and data properties as needed

    deleteitem() {
      this.UploadList.invalid.splice(this.item, 1);
    },
    getexceldata(table) {
      this.dialog = true;
      this.headerList = table.tableheader;
      this.dataList = table.tabledata;
    },
    validate_number() {
      if (this.ContNum != "") {
        if (this.CountryCode != "") {
          this.ConstructTableMethod();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText:
              "If Contact Number is selected then Select Country Code also",
          };
        }
      } else if (this.CountryCode != "") {
        if (this.ContNum != "") {
          this.ConstructTableMethod();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText:
              "If Country Code is selected then Select Contact Number also",
          };
        }
      } else {
        this.ConstructTableMethod();
      }
    },
    ConstructTableMethod() {
      if (this.date_of_birth != "") {
        this.dataList.forEach((item) => {
          item[this.date_of_birth] = this.formatDate(item[this.date_of_birth]);
        });
      }
      if (this.date_of_join != "") {
        this.dataList.forEach((item) => {
          item[this.date_of_join] = this.formatDate(item[this.date_of_join]);
        });
      }

      this.ValidHeader = [
        { text: "Member Name", value: this.EmpName },
        { text: "Member Id", value: this.EmpId },
        { text: "Country Code", value: this.CountryCode },
        { text: "Contact Number", value: this.ContNum },
        { text: "E-Mail", value: this.Email },
        { text: "Date of Birth", value: this.date_of_birth },
        { text: "Date of Join", value: this.date_of_join },

        { text: "Designation", value: this.Empdesgination },
        { text: "Employee Type", value: this.Employeetype },
      ];
      this.InValidHeader = [
        { text: "Member Name", value: this.EmpName },
        { text: "Member Id", value: this.EmpId },
        { text: "Country Code", value: this.CountryCode },
        { text: "Contact Number", value: this.ContNum },
        { text: "E-Mail", value: this.Email },
        { text: "Date of Birth", value: this.date_of_birth },
        { text: "Date of Join", value: this.date_of_join },
        { text: "Designation", value: this.Empdesgination },
        { text: "Employee Type", value: this.Employeetype },
        {
          text: "Actions",
          value: "actions",
          width: "auto",
          sortable: false,
        },
      ];
      this.StoreTableData = this.dataList;
      this.filterUserTable();
    },

    formatDate(serialNumber) {
      const baseDate = new Date(Date.UTC(1900, 0, 1));
      const date = new Date(
        baseDate.getTime() + serialNumber * 24 * 60 * 60 * 1000
      );

      // Subtract 2 days
      date.setDate(date.getDate() - 2);

      const day = date.getDate().toString().padStart(2, "0"); // Ensure two-digit day
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure two-digit month
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    filterUserTable() {
      var self = this;
      self.UploadList = {
        valid: [],
        invalid: [],
      };
      self.StoreTableData.forEach(function (cc) {
        for (let key in cc) {
          if (key == self.CountryCode) {
            if (cc[key].toString().includes("+") == false) {
              cc[key] = "+" + cc[key];
            }
          }
        }
      });
      let splCharRegex = new RegExp(/^[A-Za-z0-9 ]+$/);
      let memsplRegex = new RegExp(/^[a-z\sA-Z ]+$/);
      let numsplRegex = new RegExp(/^[+0-9]+$/);

      for (const item of this.StoreTableData) {
        console.log(item, "item");
        let invalidReasons = [];
        if (
          item[this.EmpId] == undefined ||
          item[this.EmpId] == null ||
          item[this.EmpId] == ""
        ) {
          invalidReasons.push(
            "Invalid Member ID (OR) Member ID cannot be empty"
          );
        }
        if (item[this.EmpId].length > 10) {
          invalidReasons.push(
            "Member ID Cannot contain more than 10 Characters"
          );
        }
        if (!splCharRegex.test(item[this.EmpId])) {
          invalidReasons.push("Member ID Cannot Contain special characters");
        }
        if (
          item[this.EmpName] == undefined ||
          item[this.EmpName] == null ||
          item[this.EmpName] == ""
        ) {
          invalidReasons.push(
            "Invalid Member Name (OR) Member Name cannot be empty"
          );
        }
        if (
          item[this.EmpName] == undefined ||
          item[this.EmpName] == null ||
          item[this.EmpName] == "" ||
          item[this.EmpName].length > 30
        ) {
          invalidReasons.push(
            "Member Name Cannot contain more than 30 Characters"
          );
        } else if (!memsplRegex.test(item[this.EmpName])) {
          invalidReasons.push("Member Name Cannot Contain special characters");
        }
        if (this.Empdesgination != "") {
          if (
            item[this.Empdesgination] == undefined ||
            item[this.Empdesgination] == null ||
            item[this.Empdesgination] == ""
          ) {
            invalidReasons.push(
              "Invalid Desgination Name (OR) Desgination  cannot be empty"
            );
          }
          if (
            item[this.Empdesgination] == undefined ||
            item[this.Empdesgination] == null ||
            item[this.Empdesgination] == "" ||
            item[this.Empdesgination].length > 100
          ) {
            invalidReasons.push(
              "Desgination Cannot contain more than 100 Characters"
            );
          } else if (!memsplRegex.test(item[this.Empdesgination])) {
            invalidReasons.push(
              "Desgination Name Cannot Contain special characters"
            );
          }
        }
        if (this.Employeetype != "") {
          if (
            item[this.Employeetype] == undefined ||
            item[this.Employeetype] == null ||
            item[this.Employeetype] == ""
          ) {
            invalidReasons.push(
              "Invalid Employee Type (OR) Employee Type  cannot be empty"
            );
          }
          if (
            item[this.Employeetype] == undefined ||
            item[this.Employeetype] == null ||
            item[this.Employeetype] == "" ||
            item[this.Employeetype].length > 100
          ) {
            invalidReasons.push(
              "Employee Type Cannot contain more than 100 Characters"
            );
          } else if (!memsplRegex.test(item[this.Employeetype])) {
            invalidReasons.push(
              "Employee Type Cannot Contain special characters"
            );
          }
        }
        if (this.CountryCode != "") {
          // console.log(item[this.CountryCode], "this.CountryCode");
          if (
            item[this.CountryCode] == undefined ||
            item[this.CountryCode] == null ||
            item[this.CountryCode] == "" ||
            item[this.CountryCode] == "+"
          ) {
            invalidReasons.push(
              "Invalid Country Code (OR) Country Code cannot be empty"
            );
            // console.log(invalidReasons, "invalidReasons");
          } else if (item[this.CountryCode].length > 7) {
            invalidReasons.push(
              "Country Code Cannot contain more than 7 Characters"
            );
          } else if (!numsplRegex.test(item[this.CountryCode])) {
            invalidReasons.push(
              "Country Code  Cannot Contain special characters"
            );
          }
        }
        if (this.Email != "") {
          if (
            item[this.Email] == undefined ||
            item[this.Email] == null ||
            item[this.Email] == ""
          ) {
            invalidReasons.push(
              "Invalid Email ID (OR) Email ID cannot be empty"
            );
          } else if (/.+@.+\..+/.test(item[this.Email]) != true) {
            invalidReasons.push("Invalid Email ID");
          }
        }
        if (this.date_of_birth != "") {
          // console.log(this.date_of_birth);
          if (
            item[this.date_of_birth] == undefined ||
            item[this.date_of_birth] == null ||
            item[this.date_of_birth] == ""
          ) {
            invalidReasons.push(
              "Invalid Date of Birth (OR) Date of Birth cannot be empty"
            );
          } else if (!this.isValidDateFormat(item[this.date_of_birth])) {
            invalidReasons.push("Invalid Date of Birth");
          }
        }
        if (this.date_of_join != "") {
          // console.log(this.date_of_join);
          if (
            item[this.date_of_join] == undefined ||
            item[this.date_of_join] == null ||
            item[this.date_of_join] == ""
          ) {
            invalidReasons.push(
              "Invalid Date of Join (OR) Date of Join cannot be empty"
            );
          } else if (!this.isValidDateFormat(item[this.date_of_join])) {
            invalidReasons.push("Invalid Date of Join");
          }
        }

        if (this.ContNum != "") {
          if (
            item[this.ContNum] == undefined ||
            item[this.ContNum] == null ||
            item[this.ContNum] == ""
          ) {
            invalidReasons.push(
              "Invalid Contact Number (OR) Contact Number cannot be empty"
            );
          } else if (item[this.ContNum].toString().length < 8) {
            invalidReasons.push(
              "Contact Number Cannot contain more than 10 Characters"
            );
          } else if (item[this.ContNum].toString().length > 12) {
            invalidReasons.push(
              "Contact Number Cannot contain more than 10 Characters"
            );
          } else if (!numsplRegex.test(item[this.ContNum].toString())) {
            invalidReasons.push("Contact Number must contain only numbers");
          }
        }
        item.reasons = invalidReasons;
        // console.log(invalidReasons);
        if (invalidReasons.length > 0) {
          this.UploadList.invalid.push(item);
        } else {
          this.UploadList.valid.push(item);
        }
      }
    },
    isValidDateFormat(dateString) {
      const datePattern = /^\d{2}-\d{2}-\d{4}$/;
      return datePattern.test(dateString);
      // if (!datePattern.test(dateString)) {
      //   return false; // Invalid format
      // }

      // const parts = dateString.split("-");
      // const day = parseInt(parts[0], 10);
      // const month = parseInt(parts[1], 10);
      // const year = parseInt(parts[2], 10);

      // if (isNaN(day) || isNaN(month) || isNaN(year)) {
      //   return false; // Non-numeric day, month, or year
      // }

      // // Check if month is in the valid range (1-12) and day is in a valid range for that month
      // if (
      //   month < 1 ||
      //   month > 12 ||
      //   day < 1 ||
      //   day > new Date(year, month, 0).getDate()
      // ) {
      //   return false; // Invalid day or month
      // }

      // // Check if the year is a reasonable value (e.g., not too far in the future or past)
      // const currentYear = new Date().getFullYear();
      // if (year < 1900 || year > currentYear + 10) {
      //   return false; // Invalid year
      // }

      // return true;
    },
    updateuploaddetails() {
      this.UploadList.valid.push(this.item);
      this.UploadList.invalid.splice(this.item, 1);
      this.dialogEdited = false;
    },
    editItem(item) {
      // console.log("item", item);
      this.CountryList = Country.getAllCountries();
      for (let i = 0; i < this.CountryList.length; i++) {
        this.CountryList[i].phonecode =
          this.CountryList[i].phonecode.includes("+") == false
            ? `+${this.CountryList[i].phonecode}`
            : this.CountryList[i].phonecode;
      }
      this.editStoreObj = item;
      this.dialogEdited = true;
    },
    onvertDateFormat(dateStr) {
      if (dateStr) {
        const parts = dateStr.split("-");
        // Rearrange the parts into "DD/MM/YYYY" format
        return `${parts[0]}/${parts[1]}/${parts[2]}`;
      }
      // // Split the date string by "-"
      // else {
      return dateStr;
      // }
    },
    async updateMember() {
      this.isLoadingUpload = true;
      let StoreValidUser = this.UploadList.valid;

      const self = this;
      const StoreValidUserArray = StoreValidUser.map((user) => {
        if (user[self.ContNum] == undefined) {
          var contnum = undefined;
        }

        // console.log( contnum,'contnum');
        return {
          user_name: user[self.EmpName],
          employee_id: user[self.EmpId],
          user_country_code: user[self.CountryCode],
          user_contact_number: user[self.ContNum],
          user_email_id: user[self.Email],
          user_full_contact_number:
            contnum == undefined
              ? undefined
              : user[self.CountryCode] + user[self.ContNum],
          date_of_birth: self.onvertDateFormat(user[self.date_of_birth]),
          designation: user[self.Empdesgination],
          employee_type: user[self.Employeetype],
          enrolled_on: self.onvertDateFormat(user[self.date_of_join]),
        };
      });
      // for (const [key, value] of Object.entries(inputParams)) {
      //   if (value == "") delete inputParams[key];
      // }

      try {
        var inputParams = {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          Prezence_Employee: JSON.stringify(StoreValidUserArray),
        };
        await API.graphql(
          graphqlOperation(BulkUploadusers, {
            input: inputParams,
          })
        ).then((response) => {
          // console.log("reeee", response);
          this.$store.commit("snackbar/showMessage", {
            content: JSON.parse(response.data.BulkUploadusers).Message,
            color: "green",
          });
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: JSON.parse(response.data.BulkUploadusers).Message,
          };
          this.closeEditUploadDialog();
        });
      } catch (e) {
        this.isLoadingUpload = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: e.errors[0].message,
        };
      }
    },
    closeEditUploadDialog() {
      this.dialog = false;
      this.isLoadingUpload = false;
      this.EmpName = "";
      this.EmpId = "";
      this.CountryCode = "";
      this.Email = "";
      this.headerList = "";
      this.StoreTableData = "";
      this.ContNum = "";
      this.date_of_birth = "";
      this.date_of_join = "";
      this.$emit("refresh");
    },
  },
};
</script>
