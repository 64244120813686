<template>
  <div>
    <v-dialog v-model="viewMemberSwipesDialog" persistent width="1200px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title
          >Member Swipes - {{ userDetailsObj.user_name }}
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow--hidden">
        <v-row class="mx-4" no-gutters>
          <v-col cols="12" md="6" sm="12" lg="6" xl="6">
            <v-checkbox
              v-model="bulckselectall"
              @change="checkchange()"
              class="font-weight-medium mt-4"
              label="All locations"
              v-show="memberSwipeItems.length > 1"
            ></v-checkbox>
            <v-data-table
              :height="height"
              class="overflow-y-auto mr-2"
              dense
              :headers="memberSwipeData"
              :items="memberSwipeItems"
              :items-per-page="50"
              :fixed-header="fixed"
              :loading="isLoadingMember"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.bulkselect`]="{ item }">
                <v-simple-checkbox
                  :value="isChecked(item)"
                  @input="updateJoinStatus(item)"
                  color="primary"
                  v-show="item.source != 'DATACORRECTION'"
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div v-on="on" v-if="item.source == 'LIVE'">
                      <v-icon color="#757575" small
                        >mdi-map-marker-account</v-icon
                      >
                    </div>
                    <div v-on="on" v-if="item.source == 'WHATSAPP'">
                      <v-icon color="#757575" small>mdi-whatsapp</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'CAMERA'">
                      <v-icon color="#757575" small>mdi-camera</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'FACE'">
                      <v-icon color="#757575" small
                        >mdi-face-recognition</v-icon
                      >
                    </div>
                    <div v-on="on" v-else-if="item.source == 'RESWIPE'">
                      <v-icon color="#757575" small>mdi-redo-variant</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'DATACORRECTION'">
                      <v-icon color="#757575" small>mdi-human-edit</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'PROXY'">
                      <v-icon color="#757575" small>mdi-odnoklassniki</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'BOX'">
                      <v-icon color="#757575" small
                        >mdi-card-account-details-outline</v-icon
                      >
                    </div>
                    <div v-on="on" v-else-if="item.source == 'DESKTOP'">
                      <v-icon color="#757575" small>
                        mdi-desktop-classic</v-icon
                      >
                    </div>
                    <div v-on="on" v-else-if="item.source == 'BEACON'">
                      <v-icon color="#757575" small>mdi-bluetooth-audio</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'SIMPLE'">
                      <v-icon color="#757575" small>mdi-line-scan</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'RFID'">
                      <v-icon color="#757575" small
                        >mdi-card-account-details-outline</v-icon
                      >
                    </div>
                  </template>
                  <span v-if="item.source == 'WHATSAPP'" class="white--text"
                    >Source: Whatsapp</span
                  >
                  <span v-else-if="item.source == 'DESKTOP'" class="white--text"
                    >Source : Desktop</span
                  >
                  <span v-else-if="item.source == 'CAMERA'" class="white--text"
                    >Source: QR</span
                  >
                  <span class="white--text" v-else-if="item.source == 'RESWIPE'"
                    >Source: Re-Scan</span
                  >
                  <span
                    v-else-if="item.source == 'DATACORRECTION'"
                    class="white--text"
                  >
                    Source: Data Correction</span
                  >
                  <span v-else-if="item.source == 'PROXY'" class="white--text">
                    Source: PROXY</span
                  >
                  <span v-else-if="item.source == 'BOX'" class="white--text">
                    Source: BOX</span
                  >
                  <span v-else-if="item.source == 'BEACON'" class="white--text">
                    Source: BEACON</span
                  >
                  <span v-else-if="item.source == 'FACE'" class="white--text">
                    Source: Face
                  </span>
                  <span v-else-if="item.source == 'RFID'" class="white--text">
                    Source : RFID</span
                  >
                  <span v-else-if="item.source == 'LIVE'" class="white--text">
                    Source : LIVE</span
                  >
                  <span v-else-if="item.source == 'SIMPLE'" class="white--text">
                    Source : Simple</span
                  >
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <v-alert :value="true" class="gradient-bg white--text">
                  No swipes recorded for this member!
                </v-alert>
              </template>
              <template v-slot:[`item.swipe_date`]="{ item }">
                <span v-text="get_date(item)"></span>
              </template>
              <template v-slot:[`item.swipe_time`]="{ item }">
                <span v-text="get_time(item).split(',')[1]"></span>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-if="item.is_dummy_swipe == true"
                      class="ml-2"
                      small
                      v-on="on"
                      color="primary"
                      >mdi-information-slab-circle-outline
                    </v-icon>
                  </template>
                  <span class="white--text"> {{ item.swipe_message }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.box_location`]="{ item }">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div class="overflow" v-on="on">
                      {{ item.box_location }}
                    </div>
                  </template>
                  <span class="white--text">{{ item.box_location }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="6" sm="12" lg="6" xl="6">
            <v-card class="mt-3">
              <GmapMap
                :center="
                  swipeData[0] != undefined
                    ? swipeData[0].position
                    : defaultCenter
                "
                :zoom="10"
                map-type-id="terrain"
                style="width: 100%; height: 400px"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in swipeData"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :title="' swipped at location : ' + m.location"
                  @click="center = m.position"
                  :icon="{ url: m.marker }"
                />
              </GmapMap>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import {
  GetAllSwipesForParticularEmployee,
  SwipesOfEmployee,
} from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
export default {
  props: {
    viewMemberSwipesDialog: Boolean,
    userDetailsObj: Object,
    toggle: Number,
    date: String,
  },
  components: {
    Overlay,
  },
  data: () => ({
    defaultCenter: {
      lng: 0,
      lat: 0,
    },
    bulckselectall: true,
    swipeData: [],
    geoSwipData: [],
    overlay: false,
    isLoadingMember: false,
    fixed: true,
    height: 0,
    timeZone: "",
    memberSwipeData: [
      { text: "", value: "type" },
      { text: "Swipe Location", value: "box_location" },
      { text: "Swipe Date", value: "swipe_date" },
      { text: "Swipe Time", value: "swipe_time" },
      {
        text: "",
        value: "bulkselect",
      },
    ],
    memberSwipeItems: [],
    selectedItems: [],
  }),
  watch: {
    viewMemberSwipesDialog: {
      async handler() {
        {
          if (this.viewMemberSwipesDialog == true) {
            // console.log("date", this.date);
            // console.log("toggle", this.toggle);
            this.height = window.innerHeight - 280;
            // this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.overlay = true;
            this.isLoadingMember = true;
            this.memberSwipeItems = [];
            if (this.toggle != 1) {
              await this.list_all_member_swipes();
              await this.checkchange();
              this.selectedItems = [...this.memberSwipeItems];
              await this.graph_bind();
            } else {
              await this.list_member_swipe_for_day();
              await this.checkchange();
              this.selectedItems = [...this.memberSwipeItems];
              await this.graph_bind();
            }
          }
        }
      },
      immediate: true,
    },
    selectedItems: {
      handler(newValue) {
        // If selectedItems is empty or has a length of zero, set bulckselectall to false
        if (newValue.length === 0) {
          this.bulckselectall = false;
        }
        if (
          this.memberSwipeItems.length == 0 &&
          this.selectedItems.length == 0
        ) {
          this.bulckselectall = false;
        }
        if (
          this.memberSwipeItems.length != 0 &&
          this.selectedItems.length != 0
        ) {
          if (this.memberSwipeItems.length == this.selectedItems.length) {
            this.bulckselectall = true;
          } else {
            this.bulckselectall = false;
          }
        }
      },
      deep: true, // Watch for changes deeply inside the array
    },
  },
  methods: {
    graph_bind() {
      this.swipeData = [];
      this.geoSwipData = [];
      let baseImage = "https://maps.google.com/mapfiles/ms/icons/";

      let markerImage = [
        "red-dot.png",
        "yellow-dot.png",
        "green-dot.png",
        "blue-dot.png",
        "orange-dot.png",
        "purple-dot.png",
        "pink-dot.png",
      ];
      // console.log(this.selectedItems);
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i].source != "DATACORRECTION") {
          this.geoSwipData.push({
            location: this.selectedItems[i].box_location,
            position: {
              lat: Number(this.selectedItems[i].user_lat),
              lng: Number(this.selectedItems[i].user_long),
            },
            marker:
              baseImage +
              markerImage[Math.floor(Math.random() * markerImage.length)],
          });

          this.swipeData = this.geoSwipData;
        }
        // console.log("Asasa",this.memberSwipeItems );
        // console.log("asas", this.geoSwipData);
      }
    },
    async checkchange() {
      if (this.bulckselectall) {
        this.selectedItems = [...this.memberSwipeItems];
        await this.graph_bind();
      } else {
        this.selectedItems = [];
        await this.graph_bind();
      }
    },
    isChecked(item) {
      // Check if item is in selectedItems array
      return this.selectedItems.includes(item);
    },
    async updateJoinStatus(item) {
      if (this.isChecked(item)) {
        // If item is already selected, remove it
        const index = this.selectedItems.indexOf(item);
        if (index !== -1) {
          this.selectedItems.splice(index, 1);
          await this.graph_bind();
        }
      } else {
        // If item is not selected, add it
        this.selectedItems.push(item);
        await this.graph_bind();
      }
    },
    get_date(date) {
      let a = new Date(date.swipe_time_stamp * 1000).toLocaleDateString(
        "en-US",
        {
          timeZone:
            date.time_zone == null
              ? Intl.DateTimeFormat().resolvedOptions().timeZone
              : date.time_zone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }
      );
      const [month, day, year] = a.split("/");

      // Reformatting to "day month year" format
      return `${day}/${month}/${year}`;
    },
    get_time(date) {
      let a = new Date(date.swipe_time_stamp * 1000).toLocaleTimeString(
        "en-US",
        {
          timeZone:
            date.swipe_local_timezone == null ||
            date.swipe_local_timezone == "N/A" ||
            date.swipe_local_timezone == null ||
            date.swipe_local_timezone == "IST"
              ? Intl.DateTimeFormat().resolvedOptions().timeZone
              : date.swipe_local_timezone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      );
      return a.toUpperCase();
    },
    getMethods(rec) {
      this.date = rec.date;
    },
    async list_member_swipe_for_day() {
      this.overlay = true;
      this.isLoadingMember = true;
      try {
        await API.graphql(
          graphqlOperation(SwipesOfEmployee, {
            input: {
              user_id: this.userDetailsObj.user_id,
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              swipe_time_stamp: new Date(this.date).getTime() / 1000,
            },
          })
        ).then((result) => {
          this.swipeData = [];
          this.geoSwipData = [];
          // console.log(JSON.parse(result.data.SwipesOfEmployee).Items);
          this.memberSwipeItems =
            JSON.parse(result.data.SwipesOfEmployee).Items.length != 0
              ? JSON.parse(result.data.SwipesOfEmployee).Items
              : [];
          let baseImage = "https://maps.google.com/mapfiles/ms/icons/";

          let markerImage = [
            "red-dot.png",
            "yellow-dot.png",
            "green-dot.png",
            "blue-dot.png",
            "orange-dot.png",
            "purple-dot.png",
            "pink-dot.png",
          ];
          for (let i = 0; i < this.memberSwipeItems.length; i++) {
            this.geoSwipData.push({
              location: this.memberSwipeItems[i].box_location,
              position: {
                lat: Number(this.memberSwipeItems[i].user_lat),
                lng: Number(this.memberSwipeItems[i].user_long),
              },
              marker:
                baseImage +
                markerImage[Math.floor(Math.random() * markerImage.length)],
            });

            this.swipeData = this.geoSwipData;
            // console.log("Asasa",this.memberSwipeItems );
            // console.log("asas", this.geoSwipData);
          }
        });
        this.overlay = false;
        this.isLoadingMember = false;
      } catch (error) {
        console.log(error);
        this.overlay = false;
        this.isLoadingMember = false;
      }
    },

    async list_all_member_swipes() {
      this.overlay = true;
      this.isLoadingMember = true;
      try {
        await API.graphql(
          graphqlOperation(GetAllSwipesForParticularEmployee, {
            input: {
              user_id: this.userDetailsObj.user_id,
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        ).then((result) => {
          // console.log(
          //   "result.data.GetAllSwipesForParticularEmployee.data",
          //   result.data.GetAllSwipesForParticularEmployee.data
          // ),
          this.memberSwipeItems =
            result.data.GetAllSwipesForParticularEmployee.data.length != 0
              ? result.data.GetAllSwipesForParticularEmployee.data
              : [];
          // console.log(this.memberSwipeItems, "5465");
          let baseImage = "https://maps.google.com/mapfiles/ms/icons/";
          let markerImage = [
            "red-dot.png",
            "yellow-dot.png",
            "green-dot.png",
            "blue-dot.png",
            "orange-dot.png",
            "purple-dot.png",
            "pink-dot.png",
          ];
          for (let i = 0; i < this.memberSwipeItems.length; i++) {
            if (
              this.memberSwipeItems[i].user_lat != null &&
              this.memberSwipeItems[i].user_long != null
            ) {
              this.geoSwipData.push({
                location: this.memberSwipeItems[i].box_location,
                position: {
                  lat: Number(this.memberSwipeItems[i].user_lat),
                  lng: Number(this.memberSwipeItems[i].user_long),
                },
                marker:
                  baseImage +
                  markerImage[Math.floor(Math.random() * markerImage.length)],
              });
            }
            this.swipeData = this.geoSwipData;
            // console.log(this.this.memberSwipeItems );
          }
        });
        this.overlay = false;
        this.isLoadingMember = false;
      } catch (error) {
        console.log(error);
        this.overlay = false;
        this.isLoadingMember = false;
      }
    },
    close_dialog() {
      // this.swipeData.shift();
      // console.log(this.swipeData.length, "333");
      this.geoSwipData = [];
      // console.log(this.swipeData.length, "after");

      this.$emit("close_view_member_details");
    },
  },
};
</script>

<style></style>
