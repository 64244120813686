import {
    API,
    graphqlOperation
} from "aws-amplify";
import {
    list_employee_type
} from "@/graphql/queries.js";
export const GetEmployeetype = {
    data: () => ({

        GetitemsEmployee: []

    }),
    methods: {
        async GetEmployeetype() {
            try {

                let result = await API.graphql(
                    graphqlOperation(list_employee_type, {
                        input: {
                            organisation_id:
                                this.$store.getters.get_org_details[0].organisation_id,

                        },
                    })
                );
                this.GetitemsEmployee = [];
                var response = JSON.parse(result.data.list_employee_type);
                if (response.Status == "SUCCESS") {
                    this.GetitemsEmployee = response.data;
                }
                else {
                    this.GetitemsEmployee = [];
                }
            } catch (err) {
                this.GetitemsEmployee = [];
                console.log("erro location", err);
                this.$store.commit("snackbar/showMessage", {
                    content: err.errors[0].message,
                    color: "red",
                });
            }
        },
    }
}