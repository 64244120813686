<template>
  <v-dialog v-model="confDeleteMemberDialog" persistent width="390px">
    <v-card class="overflow-hidden">
      <v-toolbar dense class="gradient-bg elevation-0"> </v-toolbar>
      <v-card-text align="center" style="font-weight: bold; text-align: justify"
        >{{ deleteText }}
      </v-card-text>
      <v-form ref="form">
        <v-card-text class="mt-n3" align="center">
          <v-icon
            large
            class="mt-n5"
            :color="deleteColor"
            v-if="memberStatus == 'INACTIVE'"
            >{{ deleteIcon }}</v-icon
          >
          <v-row no-gutters class="mx-0 my-n1" v-if="memberStatus == 'ACTIVE'">
            <v-col cols="6" md="6" sm="6" lg="6" xl="6" xs="6">
              <v-dialog
                ref="dialog"
                v-model="modal"
                color="#f4813f"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    dense
                    :rules="[(v) => !!v || 'Required']"
                    outlined
                    class="mr-2"
                    persistent-hint
                    label="Relieving date *"
                    color="#f4813f"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :max="new Date().toISOString().substr(0, 10)"
                  :min="mindate"
                  v-model="date"
                  color="#f4813f"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="#f4813f" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="#f4813f" @click="$refs.dialog.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="12"
              lg="6"
              xl="6"
              xs="12"
              v-if="show == 1"
            >
              <v-dialog
                ref="MondayStartTime"
                v-model="MondayStartDialog"
                :return-value.sync="MondayStartTime"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="MondayStartTime"
                    label="Select Time *"
                    append-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    :rules="rules"
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="MondayStartTime"
                  format="24hr"
                  :max="
                    date == new Date().toISOString().substr(0, 10)
                      ? new Date().toLocaleTimeString().split(' ')[1] == 'PM'
                        ? (
                            parseInt(
                              new Date().toLocaleTimeString().split(':')[0]
                            ) + 12
                          ).toString() +
                          ' :' +
                          new Date().toLocaleTimeString().split(':')[1]
                        : new Date().toLocaleTimeString().split(' ')[1] == 'AM'
                        ? new Date().toLocaleTimeString().split(':')[0] +
                          ':' +
                          new Date().toLocaleTimeString().split(':')[1]
                        : new Date().toLocaleTimeString().split(':')[0]
                      : ''
                  "
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="MondayStartDialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.MondayStartTime.save(MondayStartTime)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-n8">
        <v-spacer></v-spacer>
        <v-btn color="primary" small text @click="closeDelete">Cancel</v-btn>
        <v-btn
          color="red darken-1"
          class="white--text"
          small
          :loading="deleteLoading"
          @click="deleteItemConfirm"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DeactivateAndReactivateMember } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";

export default {
  props: {
    confDeleteMemberDialog: Boolean,
    deleteText: String,
    deleteIcon: String,
    deleteColor: String,
    deleteLoading: Boolean,
    date: String,
    modal: Boolean,
    deleteUserItem: Object,
    memberStatus: String,
  },
  data: () => ({
    deleteMessage: "",
    MondayStartTime: new Date().toLocaleTimeString(),
    MondayStartDialog: false,
    show: 0,
    deleteUserItemObject: {},
    mindate: "",
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    confDeleteMemberDialog(val) {
      if (val == true) {
        this.deleteUserItemObject = this.deleteUserItem;
        this.mindate =
          this.deleteUserItemObject.user_created_on != null ||
          this.deleteUserItemObject.user_created_on != undefined
            ? this.get_date(this.deleteUserItemObject.user_created_on).split(
                ","
              )[0]
            : "";
        this.mindate = this.formatDateToISO(this.mindate);
      }
    },
    date(val) {
      this.dateFormatted = this.formatDate(val);
      this.date = new Date(val).toISOString().substr(0, 10);
    },
  },
  methods: {
    formatDateToISO(date) {
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    closeDelete() {
      this.$emit("close_conf_delete_dialog");
    },
    async deleteItemConfirm() {
      if (this.$refs.form.validate()) {
        var new_time = Math.floor(
          new Date(
            this.date.split("-")[0],
            this.date.split("-")[1] - 1,
            this.date.split("-")[2],
            this.MondayStartTime.split(":")[0],
            this.MondayStartTime.split(":")[1]
          ).getTime() / 1000
        );
        this.deleteLoading = true;
        try {
          await API.graphql(
            graphqlOperation(DeactivateAndReactivateMember, {
              input: {
                organisation_id:
                  this.$store.getters.get_org_details[0].organisation_id,
                action_type:
                  this.memberStatus == "ACTIVE" ? "DEACTIVATE" : "REACTIVATE",
                user_id: this.deleteUserItemObject.user_id,
                relieving_date: this.memberStatus == "ACTIVE" ? new_time : "",
                action_taken_by: this.$store.getters.get_useremail,
              },
            })
          ).then((res) => {
            this.$store.commit("snackbar/showMessage", {
              content: res.data.DeactivateAndReactivateMember.Message,
              color: "green",
            });
            this.confDeleteMemberDialog = false;

            this.deleteLoading = false;
            this.$emit("success_conf_delete");
          });
        } catch (err) {
          this.$store.commit("snackbar/showMessage", {
            content: err.errors[0].message,
            color: "red",
          });
          this.deleteLoading = false;
        }
      }
    },
  },
};
</script>

<style>
.primary {
  color: #d97d54 !important;
}
</style>
