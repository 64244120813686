<template>
  <div>
    <v-dialog v-model="viewMembersProfile" persistent width="280px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        Profile
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card height="290px" v-if="userDetailsObj.face_s3_key != null">
        <v-card-text align="center" justify="center" class="pt-10">
       
          <div
            style="
              position: relative;
              display: inline-flex;
              align-items: center;
            "
          >
            <div
              style="
                border: 2px solid gray;
                padding: 0px; /* Adjust padding as needed */
                margin-right: 8px; /* Adjust margin as needed */
              "
            >
              <v-img
                max-height="145"
                width="145"
                :src="`https://przncemembersprofile.s3.ap-south-1.amazonaws.com/${
                  userDetailsObj.face_s3_key
                }?${new Date().getTime()}`"
                contain
              ></v-img>
              <!-- MDI-pencil icon -->
              <!-- <v-icon
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  padding: 4px; /* Adjust padding as needed */
                  border-radius: 50%;
                  margin-top: -12px;
                  margin-right: -16px;
                  /* Set color to orange */
                "
                color="red"
                @click="change_profile()"
                >mdi-pencil</v-icon
              > -->
            </div>
          </div>

          <br />
          <span class="black--text subtitle-2">{{
            userDetailsObj.user_name
          }}</span
          ><br />
          <span class="black--text subtitle-2">{{
            userDetailsObj.employee_id
          }}</span
          ><br />
          <v-btn class="text-capitalize gradient-bg white--text"  text small   @click="change_profile()"
            >Change Profile</v-btn
          >
        </v-card-text>
      </v-card>
      <v-card height="280px" v-else>
        <v-card-text align="center" justify="center" class="pt-10">
          <!-- <div style="border: 1px solid gray;width: fit-content;">
                        <v-img height="135" width="140"><v-icon color="primary" large dark> mdi-account-circle </v-icon></v-img>
                    </div><br/> -->
          <br /><br /><br />
          <span class="black--text subtitle-2">{{
            userDetailsObj.user_name
          }}</span
          ><br />
          <span class="black--text subtitle-2">{{
            userDetailsObj.employee_id
          }}</span
          ><br />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn  class=" text-capitalize gradient-bg white--text" small @click="change_profile()"
            >Update Profile Picture</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="componentCheck == 1">
      <UploadProfile
        :Upoloadimage="Upoloadimage"
        @clicked="Upoloadimage = false"
        :userDetailsObj="userDetailsObj"
        @close_dailog_upload="close_subdailog()"
        @update="updatedailog()"
      />
    </div>
  </div>
</template>

<script>
import UploadProfile from "@/components/Dialogs/UploadProfile.vue";
export default {
  components: {
    UploadProfile,
  },
  props: {
    viewMembersProfile: Boolean,
    userDetailsObj: Object,
  },
  data: () => ({
    componentCheck: 0,
    Upoloadimage: false,
  }),

  methods: {
    close_dialog() {
      this.$emit("close_view_profile_dialog");
    },
    close_subdailog() {
      this.Upoloadimage = false;
      this.$emit("close_popup", 0);
    },
    change_profile() {
      this.componentCheck = 1;
      this.Upoloadimage = true;
    },
  },
};
</script>

<style>
</style>