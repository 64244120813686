<template>
  <div>
    <v-dialog v-model="viewMembertrackDialog" persistent width="600px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title
          >Live Location Tracking - {{ userDetailsObj.user_name }}
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow--hidden">
        <v-row class="mx-4" no-gutters>
          <!-- <v-col cols="12" md="6" sm="12" lg="6" xl="6"> -->
          <!-- <v-checkbox
                v-model="bulckselectall"
                @change="checkchange()"
                class="font-weight-medium mt-4"
                label="All locations"
                v-show="memberSwipeItems.length > 1"
              ></v-checkbox> -->

          <!-- <v-data-table
              :height="height"
              class="overflow-y-auto mr-2"
              dense
              :headers="memberSwipeData"
              :items="memberSwipeItems"
              :items-per-page="50"
              :fixed-header="fixed"
              :loading="isLoadingMember"
              loading-text="Loading... Please wait"
            > -->
          <!-- <template v-slot:[`item.bulkselect`]="{ item }">
                  <v-simple-checkbox
                    :value="isChecked(item)"
                    @input="updateJoinStatus(item)"
                    color="primary"
                    v-show="item.source != 'DATACORRECTION'"
                  ></v-simple-checkbox>
                </template> -->
          <!-- <template v-slot:[`item.type`]="{ item }">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div v-on="on" v-if="item.source == 'LIVE'">
                      <v-icon color="#757575" small
                        >mdi-map-marker-account</v-icon
                      >
                    </div>
                    <div v-on="on" v-if="item.source == 'WHATSAPP'">
                      <v-icon color="#757575" small>mdi-whatsapp</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'CAMERA'">
                      <v-icon color="#757575" small>mdi-camera</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'FACE'">
                      <v-icon color="#757575" small
                        >mdi-face-recognition</v-icon
                      >
                    </div>
                    <div v-on="on" v-else-if="item.source == 'RESWIPE'">
                      <v-icon color="#757575" small>mdi-redo-variant</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'DATACORRECTION'">
                      <v-icon color="#757575" small>mdi-human-edit</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'PROXY'">
                      <v-icon color="#757575" small>mdi-odnoklassniki</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'BOX'">
                      <v-icon color="#757575" small
                        >mdi-card-account-details-outline</v-icon
                      >
                    </div>
                    <div v-on="on" v-else-if="item.source == 'DESKTOP'">
                      <v-icon color="#757575" small>
                        mdi-desktop-classic</v-icon
                      >
                    </div>
                    <div v-on="on" v-else-if="item.source == 'BEACON'">
                      <v-icon color="#757575" small>mdi-bluetooth-audio</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'RFID'">
                      <v-icon color="#757575" small
                        >mdi-card-account-details-outline</v-icon
                      >
                    </div>
                  </template>
                  <span v-if="item.source == 'WHATSAPP'" class="white--text"
                    >Source: Whatsapp</span
                  >
                  <span v-else-if="item.source == 'DESKTOP'" class="white--text"
                    >Source : Desktop</span
                  >
                  <span v-else-if="item.source == 'CAMERA'" class="white--text"
                    >Source: QR</span
                  >
                  <span class="white--text" v-else-if="item.source == 'RESWIPE'"
                    >Source: Re-Scan</span
                  >
                  <span
                    v-else-if="item.source == 'DATACORRECTION'"
                    class="white--text"
                  >
                    Source: Data Correction</span
                  >
                  <span v-else-if="item.source == 'PROXY'" class="white--text">
                    Source: PROXY</span
                  >
                  <span v-else-if="item.source == 'BOX'" class="white--text">
                    Source: BOX</span
                  >
                  <span v-else-if="item.source == 'BEACON'" class="white--text">
                    Source: BEACON</span
                  >
                  <span v-else-if="item.source == 'FACE'" class="white--text">
                    Source: Face Scan</span
                  >
                  <span v-else-if="item.source == 'RFID'" class="white--text">
                    Source : RFID</span
                  >
                  <span v-else-if="item.source == 'LIVE'" class="white--text">
                    Source : LIVE</span
                  >
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <v-alert :value="true" class="gradient-bg white--text">
                  No swipes recorded for this member!
                </v-alert>
              </template>
              <template v-slot:[`item.swipe_date`]="{ item }">
                <span v-text="get_date(item)"></span>
              </template>
              <template v-slot:[`item.swipe_time`]="{ item }">
                <span v-text="get_time(item).split(',')[1]"></span>
              </template>
              <template v-slot:[`item.box_location`]="{ item }">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div class="overflow" v-on="on">
                      {{ item.box_location }}
                    </div>
                  </template>
                  <span class="white--text">{{ item.box_location }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col> -->
          <v-col cols="12" md="12" sm="12" lg="12" xl="12">
            <v-toolbar dense elevation="0" class="mt-4">
              <v-dialog
                ref="dialog"
                v-model="modal"
                color="#f4813f"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    dense
                    outlined
                    readonly
                    class="mt-6 ml-2"
                    persistent-hint
                    label="Filter by Date"
                    color="#f4813f"
                    append-icon="mdi-calendar"
                    style="max-width: 150px"
                    @click:append="date ? getcal() : getcal()"
                    v-bind="attrs"
                    v-on="on"
                    r
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  :min="minmonth"
                  :max="new Date().toISOString().substr(0, 10)"
                  v-model="date"
                  color="#f4813f"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="#f4813f" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="#f4813f"
                    v-on:click="list_all_member_swipes()"
                    @click="$refs.dialog.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-toolbar>
            <v-card
              class="mt-3 mb-5"
              v-if="successdata == true && swipeData.length != 0"
            >
              <GmapMap
                :center="
                  swipeData[0] != undefined
                    ? swipeData[0].position
                    : defaultCenter
                "
                :zoom="10"
                map-type-id="terrain"
                style="width: 100%; height: 400px"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in swipeData"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  @click="center = m.position"
                  :title="'Time : ' + m.location"
                  :icon="{ url: m.marker }"
                />
                <GmapPolyline
                  v-if="swipeData.length >= 2"
                  :path="swipeData.map((item) => item.position)"
                  :options="{
                    strokeColor: '#FF0000',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                  }"
                /> 
              </GmapMap>
            </v-card>
            <v-card v-else flat>
              <v-card-subtitle class="text-center"
                ><b>No location was tracked for the day.</b></v-card-subtitle
              >
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <Overlay :overlay="overlay" />
  </div>
</template>
  
  <script>
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { GetLiveTrackData } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
export default {
  props: {
    viewMembertrackDialog: Boolean,
    userDetailsObj: Object,
    toggle: Number,
    date: String,
  },
  components: {
    Overlay,
  },
  data: () => ({
    defaultCenter: {
      lng: 0,
      lat: 0,
    },
    bulckselectall: true,
    swipeData: [],
    geoSwipData: [],
    date: new Date().toISOString().substr(0, 10),
    modal: false,
    overlay: false,
    isLoadingMember: false,
    fixed: true,
    height: 0,
    timeZone: "",
    successdata: false,
    minmonth: "",
    selectedDate: "",
    memberSwipeData: [
      { text: "", value: "type" },
      { text: "Swipe Location", value: "box_location" },
      { text: "Swipe Date", value: "swipe_date" },
      { text: "Swipe Time", value: "swipe_time" },
      // {
      //   text: "",
      //   value: "bulkselect",
      // },
    ],
    memberSwipeItems: [],
    selectedItems: [],
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    viewMembertrackDialog: {
      async handler() {
        {
          if (this.viewMembertrackDialog == true) {
            // console.log("date", this.date);
            // console.log("toggle", this.toggle);
            this.selectedDate = this.get_orgdate(
              this.$store.getters.get_org_details[0].organisation_created_on
            );

            this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
              "YYYY-MM-DD"
            );
            this.height = window.innerHeight - 280;
            // this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.overlay = true;
            this.isLoadingMember = true;
            this.memberSwipeItems = [];
            await this.list_all_member_swipes();
          }
        }
      },
      immediate: true,
    },
    selectedItems: {
      handler(newValue) {
        // If selectedItems is empty or has a length of zero, set bulckselectall to false
        if (newValue.length === 0) {
          this.bulckselectall = false;
        }
        if (
          this.memberSwipeItems.length == 0 &&
          this.selectedItems.length == 0
        ) {
          this.bulckselectall = false;
        }
        if (
          this.memberSwipeItems.length != 0 &&
          this.selectedItems.length != 0
        ) {
          if (this.memberSwipeItems.length == this.selectedItems.length) {
            this.bulckselectall = true;
          } else {
            this.bulckselectall = false;
          }
        }
      },
      deep: true, // Watch for changes deeply inside the array
    },
  },
  methods: {
    getcal() {
      this.modal = true;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    get_orgdate(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    graph_bind() {
      this.swipeData = [];
      this.geoSwipData = [];
      let baseImage = "https://maps.google.com/mapfiles/ms/icons/";

      let markerImage = [
        "red-dot.png",
        "yellow-dot.png",
        "green-dot.png",
        "blue-dot.png",
        "orange-dot.png",
        "purple-dot.png",
        "pink-dot.png",
      ];
      // console.log(this.selectedItems);
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i].source != "DATACORRECTION") {
          this.geoSwipData.push({
            location: this.selectedItems[i].box_location,
            position: {
              lat: Number(this.selectedItems[i].user_lat),
              lng: Number(this.selectedItems[i].user_long),
            },
            marker:
              baseImage +
              markerImage[Math.floor(Math.random() * markerImage.length)],
          });

          this.swipeData = this.geoSwipData;
        }
        // console.log("Asasa",this.memberSwipeItems );
        // console.log("asas", this.geoSwipData);
      }
    },
    async checkchange() {
      if (this.bulckselectall) {
        this.selectedItems = [...this.memberSwipeItems];
        await this.graph_bind();
      } else {
        this.selectedItems = [];
        await this.graph_bind();
      }
    },
    isChecked(item) {
      // Check if item is in selectedItems array
      return this.selectedItems.includes(item);
    },
    async updateJoinStatus(item) {
      if (this.isChecked(item)) {
        // If item is already selected, remove it
        const index = this.selectedItems.indexOf(item);
        if (index !== -1) {
          this.selectedItems.splice(index, 1);
          await this.graph_bind();
        }
      } else {
        // If item is not selected, add it
        this.selectedItems.push(item);
        await this.graph_bind();
      }
    },
    get_date(date) {
      let a = new Date(date.swipe_time_stamp * 1000).toLocaleDateString(
        "en-US",
        {
          timeZone:
            date.time_zone == null
              ? Intl.DateTimeFormat().resolvedOptions().timeZone
              : date.time_zone,
          // timeZone:  Intl.DateTimeFormat().resolvedOptions().timeZone ,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }
      );
      const [month, day, year] = a.split("/");

      // Reformatting to "day month year" format
      return `${day}/${month}/${year}`;
    },
    get_time(date) {
      let a = new Date(date.swipe_time_stamp * 1000).toLocaleTimeString(
        "en-US",
        {
          // timeZone:
          //   date.swipe_local_timezone == null ||
          //   date.swipe_local_timezone == "N/A" ||
          //   date.swipe_local_timezone == null
          //     ? Intl.DateTimeFormat().resolvedOptions().timeZone
          //     : date.swipe_local_timezone,
          timeZone:  Intl.DateTimeFormat().resolvedOptions().timeZone ,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      );
      return a.toUpperCase();
    },
    getMethods(rec) {
      this.date = rec.date;
    },

    async list_all_member_swipes() {
      this.overlay = true;
      this.isLoadingMember = true;
      try {
        await API.graphql(
          graphqlOperation(GetLiveTrackData, {
            input: {
              user_id: this.userDetailsObj.user_id,
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              limit: 100,
              next_token: null,
              track_date: this.date,
            },
          })
        ).then((result) => {
    
          this.successdata = true;
          this.geoSwipData = [];
          this.memberSwipeItems = [];
          this.memberSwipeItems =
            result.data.GetLiveTrackData.data.length != 0
              ? result.data.GetLiveTrackData.data
              : [];
        //  console.log(this.memberSwipeItems, "5465");
          let baseImage = "https://maps.google.com/mapfiles/ms/icons/";
          let markerImage = [
           
            "blue-dot.png",
           
          ];
          for (let i = 0; i < this.memberSwipeItems.length; i++) {
            if (
              this.memberSwipeItems[i].user_lat != null &&
              this.memberSwipeItems[i].user_long != null
            ) {
              this.geoSwipData.push({
                location: this.get_time_only(this.memberSwipeItems[i].track_time).split(',')[1],
                position: {
                  lat: Number(this.memberSwipeItems[i].user_lat),
                  lng: Number(this.memberSwipeItems[i].user_long),
                },
                marker:
                  baseImage +
                  markerImage[Math.floor(Math.random() * markerImage.length)],
              });
            }
            this.swipeData = this.geoSwipData;
            // console.log(this.this.memberSwipeItems );
          }
        });
        this.overlay = false;
        this.isLoadingMember = false;
      } catch (error) {
        this.successdata = false;
        console.log(error);
        this.overlay = false;
        this.isLoadingMember = false;
      }
    },
    get_time_only(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    close_dialog() {
      // this.swipeData.shift();
      // console.log(this.swipeData.length, "333");
      this.geoSwipData = [];
      // console.log(this.swipeData.length, "after");

      this.$emit("close_view_member_details");
    },
  },
};
</script>
  
  <style></style>
  